import { useEffect, useState } from 'react'

import { Cancel, Save } from '@mui/icons-material'
import { Button, Card, CardContent, CircularProgress, Modal, InputLabel, Typography, FormControlLabel, Switch, Select, FormControl, MenuItem, LinearProgress } from '@mui/material'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserSettings, resetUserSettings, updateUserSettings } from '../../store/actions/userSettings'
import { getLanguages, getLoadingUserSettings, getConfiguration, getHasFetchUserSettingsError, getLoadingUpdateUserSettings } from '../../store/reducers/userSettings'
import { modalStyle } from '../../styles/styles'

const UserSettingsModal = (props) => {
    const { configureSettingsModalOpen, handleCloseSettingsModal, selectedUser } = props
    const dispatch = useDispatch()
    const loading = useSelector(getLoadingUpdateUserSettings)
    const loadingUserSettings = useSelector(getLoadingUserSettings)
    const hasFetchUserSettingsError = useSelector(getHasFetchUserSettingsError)
    const languages = useSelector(getLanguages)
    const configuration = useSelector(getConfiguration)

    const [selectedLanguageId, setSelectedLanguageId] = useState('')
    const [groupCustomers, setGroupCustomers] = useState(false)

    useEffect(() => {
        const userId = selectedUser.id
        dispatch(fetchUserSettings(userId))
    }, [dispatch, selectedUser])

    useEffect(() => {
        if (configuration && typeof configuration.language_id !== 'undefined') {
            setSelectedLanguageId(configuration.language_id)
        }
        if (configuration && typeof configuration.group_customers !== 'undefined') {
            setGroupCustomers(configuration.group_customers === 1)
        }
    }, [configuration])

    const handleSelectChange = (event) => {
        const languageId = event.target.value
        setSelectedLanguageId(languageId)
    }

    const handleSwitchChange = (event) => {
        const newGroupCustomers = event.target.checked
        setGroupCustomers(newGroupCustomers)
    }

    const handleSubmit = (values, actions) => {
        dispatch(
            updateUserSettings(selectedUser, {
                language_id: selectedLanguageId,
                group_customers: groupCustomers,
            })
        );
        actions.setSubmitting(false);
    };

    const handleResetAndCloseModal = () => {
        handleCloseSettingsModal()
        dispatch(resetUserSettings())
    }

    return (
        <Modal
            open={!hasFetchUserSettingsError && configureSettingsModalOpen}
            onClose={handleResetAndCloseModal}
        >
            <Card sx={{ ...modalStyle, paddingBottom: 0 }}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Configuración del usuario
                    </Typography>
                    <Typography align='center' component='div' variant='h6'>{selectedUser.username}</Typography>
                </CardContent>
                {loadingUserSettings ? (
                    <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 200 }}>
                        <CircularProgress />
                    </CardContent>
                ) : (
                    <Formik
                        initialValues={{
                            language: null,
                            groupCustomers: false,
                        }}
                        onSubmit={handleSubmit}
                        onReset={handleCloseSettingsModal}
                    >
                        <Form>
                            <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <FormControl sx={{ width: 200 }}>
                                    <InputLabel id='language-label' size='small'>Idioma</InputLabel>
                                    <Select
                                        size='small'
                                        labelId='language-label'
                                        label='Idioma'
                                        id='language'
                                        disabled={loading}
                                        value={selectedLanguageId}
                                        onChange={handleSelectChange}
                                    >
                                        {languages.map((language) => (
                                            <MenuItem key={language.id} value={language.id}>
                                                {language.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControlLabel
                                    sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                                    control={
                                        <Switch
                                            id='groupCustomers'
                                            disabled={loading}
                                            checked={groupCustomers}
                                            onChange={handleSwitchChange}
                                        />}
                                    label='Agrupar clientes'
                                />
                            </CardContent>
                            <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant='contained' color='error' size='small'
                                    onClick={handleResetAndCloseModal}
                                    disabled={loading}
                                    startIcon={<Cancel />}
                                >
                                    Cerrar
                                </Button>
                                <Button
                                    type='submit'
                                    variant='contained' color='primary' size='small'
                                    sx={{ marginLeft: '1em' }}
                                    disabled={loading}
                                    startIcon={<Save />}
                                >
                                    Actualizar
                                </Button>
                            </CardContent>
                        </Form>
                    </Formik>
                )}
                <CardContent>
                    {loading && (
                        <LinearProgress />
                    )}
                </CardContent>
            </Card>
        </Modal>
    )
}

export default UserSettingsModal