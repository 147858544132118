import { actionTypes } from '../actions/accessData'

const initialState = {
    loadingCompaniesAndCustomers: false,
    companies: [],
    customers: [],
    loadingContracts: false,
    contracts: [],
    loadingServices: false,
    services: [],
}

export const getloadingCompaniesAndCustomers = (state) => state.accessData.loadingCompaniesAndCustomers
export const getCompanies = (state) => state.accessData.companies
export const getCustomers = (state) => state.accessData.customers
export const getLoadingContracts = (state) => state.accessData.loadingContracts
export const getContracts = (state) => state.accessData.contracts
export const getLoadingServices = (state) => state.accessData.loadingServices
export const getServices = (state) => state.accessData.services

const accessData = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_COMPANIES_AND_CUSTOMERS_REQUEST:
            return {
                ...state,
                loadingCompaniesAndCustomers: true,
            }
        case actionTypes.FETCH_COMPANIES_AND_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loadingCompaniesAndCustomers: false,
                companies: action.companies,
                customers: action.customers,
            }
        case actionTypes.FETCH_COMPANIES_AND_CUSTOMERS_FAILURE:
            return {
                ...state,
                loadingCompaniesAndCustomers: false,
                companies: [],
                customers: [],
            }
        case actionTypes.RESET_COMPANIES_AND_CUSTOMERS:
            return {
                ...state,
                companies: [],
                customers: [],
            }
        case actionTypes.FETCH_CONTRACTS_REQUEST:
            return {
                ...state,
                loadingContracts: true,
            }
        case actionTypes.FETCH_CONTRACTS_SUCCESS:
            return {
                ...state,
                loadingContracts: false,
                contracts: action.contracts,
            }
        case actionTypes.FETCH_CONTRACTS_FAILURE:
            return {
                ...state,
                loadingContracts: false,
                contracts: [],
            }
        case actionTypes.RESET_CONTRACTS:
            return {
                ...state,
                contracts: [],
            }
        case actionTypes.FETCH_SERVICES_REQUEST:
            return {
                ...state,
                loadingServices: true,
            }
        case actionTypes.FETCH_SERVICES_SUCCESS:
            return {
                ...state,
                loadingServices: false,
                services: action.services,
            }
        case actionTypes.FETCH_SERVICES_FAILURE:
            return {
                ...state,
                loadingServices: false,
                services: [],
            }
        case actionTypes.RESET_SERVICES:
            return {
                ...state,
                services: [],
            }
        default:
            return state
    }
}

export default accessData
