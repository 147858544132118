import { Fragment } from 'react'

import { Cancel, Person, PersonOff } from '@mui/icons-material'
import { Button, Card, CardContent, LinearProgress, Modal, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { toggleUserStatus } from '../../store/actions/users'
import { getLoadingUsers } from '../../store/reducers/users'
import { modalStyle } from '../../styles/styles'

const ChangeUserStatusModal = (props) => {
    const { changeUserStatusModalOpen, handleCloseChangeUserStatusModal, selectedUser } = props
    const dispatch = useDispatch()
    const loadingUsers = useSelector(getLoadingUsers)

    const handleToggleUserStatus = (userId) => dispatch(toggleUserStatus(userId)).then(() => handleCloseModal())
    const handleCloseModal = () => handleCloseChangeUserStatusModal()

    return (
        <Fragment>
            <Modal
                open={changeUserStatusModalOpen}
                onClose={handleCloseModal}
            >
                <Card sx={modalStyle}>
                    <CardContent>
                        <Typography align='center' variant='h5'>
                            {selectedUser.disabled ? 'Habilitar usuario' : 'Deshabilitar usuario'}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Typography align='center'>¿Está seguro de que desea {selectedUser.disabled ? 'habilitar' : 'deshabilitar'} el usuario</Typography>
                        <Typography align='center' component='div' variant='h6'>{selectedUser.username}{(selectedUser.email !== selectedUser.username) && ' (' + selectedUser.email + ')'}</Typography>
                        <Typography align='center'>?</Typography>
                    </CardContent>
                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant='contained'
                            color='error' size='small'
                            onClick={handleCloseModal}
                            disabled={loadingUsers}
                            startIcon={<Cancel />}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant='contained'
                            color='primary' size='small'
                            onClick={() => handleToggleUserStatus(selectedUser.id)}
                            sx={{ marginLeft: '1em' }}
                            disabled={loadingUsers}
                            startIcon={selectedUser.disabled ? <Person /> : <PersonOff />}
                        >
                            Confirmar
                        </Button>
                    </CardContent>
                    {loadingUsers && (
                        <CardContent>
                            <LinearProgress />
                        </CardContent>
                    )}
                </Card>
            </Modal>
        </Fragment>
    )
}

export default ChangeUserStatusModal
