import { Fragment, useState } from 'react'

import { Apps, Groups, Menu, People } from '@mui/icons-material'
import { Alert, AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Snackbar, Toolbar, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Link, Outlet, useLocation } from 'react-router-dom'

import { getCanManageApps, getCanManageRoleAssociations, getCanManageUsers, getLoadingAbilities } from '../store/reducers/abilities'
import { getMessage, getSeverity, getShowNotifications } from '../store/reducers/notifications'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
const drawerWidth = 160

function ResponsiveDrawer(props) {
    const { window } = props
    const [mobileOpen, setMobileOpen] = useState(false)
    let location = useLocation()

    const loadingAbilities = useSelector(getLoadingAbilities)
    const canManageApps = useSelector(getCanManageApps)
    const canManageUsers = useSelector(getCanManageUsers)
    const canManageRoleAssociations = useSelector(getCanManageRoleAssociations)
    const showNotifications = useSelector(getShowNotifications)
    const notificationSeverity = useSelector(getSeverity)
    const notificationMessage = useSelector(getMessage)

    const menuItems = [
        { id: 1, title: 'Usuarios', show: canManageUsers, icon: <People />, to: '/' },
        { id: 2, title: 'Roles', show: canManageRoleAssociations, icon: <Groups />, to: '/roles' },
        { id: 3, title: 'Apps', show: canManageApps, icon: <Apps />, to: '/apps' },
    ]

    const drawer = (
        <Fragment>
            <Toolbar />
            <Divider />
            <List>
                {menuItems.map((menuItem) => menuItem.show ?
                    <ListItem key={menuItem.id} disablePadding>
                        <ListItemButton component={Link} to={menuItem.to} disabled={loadingAbilities} selected={location.pathname === menuItem.to}>
                            <ListItemIcon>{menuItem.icon}</ListItemIcon>
                            <ListItemText primary={menuItem.title} />
                        </ListItemButton>
                    </ListItem>
                    : null
                )}
            </List>
        </Fragment>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position='fixed'
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <Menu />
                    </IconButton>
                    <Typography variant='h6' noWrap component='div'>
                        Portal del Cliente - Panel de Administración
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component='nav'
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label='mailbox folders'
            >
                <Drawer
                    container={container}
                    variant='temporary'
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant='permanent'
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component='main'
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {showNotifications && (
                    <Snackbar
                        open={showNotifications}
                        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                    >
                        <Alert severity={notificationSeverity}>{notificationMessage}</Alert>
                    </Snackbar>
                )}
                <Outlet />
            </Box>
        </Box>
    )
}


export default ResponsiveDrawer
