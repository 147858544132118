import { actionTypes } from '../actions/userSettings'

const initialState = {
    loadingUpdateUserSettings: false,
    loadingUserSettings: false,
    hasFetchUserSettingsError: false,
    configuration: [],
    languages: [],
}

export const getLoadingUpdateUserSettings = (state) => state.userSettings.loadingUpdateUserSettings
export const getLoadingUserSettings = (state) => state.userSettings.loadingUserSettings
export const getHasFetchUserSettingsError = (state) => state.userSettings.hasFetchUserSettingsError
export const getConfiguration = (state) => state.userSettings.configuration
export const getLanguages = (state) => state.userSettings.languages

const userSettings = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USER_SETTING_REQUEST:
            return {
                ...state,
                loadingUpdateUserSettings: true,
            }
        case actionTypes.FETCH_USER_SETTINGS_REQUEST:
            return {
                ...state,
                loadingUserSettings: true,
                hasFetchUserSettingsError: false,

            }
        case actionTypes.FETCH_USER_SETTINGS_SUCCESS:
            return {
                ...state,
                loadingUserSettings: false,
                configuration: action.configuration,
                languages: action.languages,
            }
        case actionTypes.FETCH_USER_SETTINGS_FAILURE:
            return {
                ...state,
                loadingUserSettings: false,
                hasFetchUserSettingsError: true,
                configuration: [],
                languages: [],
            }
        case actionTypes.UPDATE_USER_SETTING_SUCCESS:
        case actionTypes.UPDATE_USER_SETTING_FAILURE:
            return {
                ...state,
                loadingUpdateUserSettings: false,
            }
        case actionTypes.RESET_USER_SETTINGS:
            return {
                ...state,
                configuration: [],
                languages: [],
            }
        default:
            return state
    }
}

export default userSettings
