import { setNotification } from './notifications'

export const actionTypes = {
    FETCH_USER_SETTINGS_REQUEST: 'FETCH_USER_SETTINGS_REQUEST',
    FETCH_USER_SETTINGS_SUCCESS: 'FETCH_USER_SETTINGS_SUCCESS',
    FETCH_USER_SETTINGS_FAILURE: 'FETCH_USER_SETTINGS_FAILURE',
    RESET_USER_SETTINGS: 'RESET_USER_SETTINGS',
    UPDATE_USER_SETTING_REQUEST: 'UPDATE_USER_SETTING_REQUEST',
    UPDATE_USER_SETTING_SUCCESS: 'UPDATE_USER_SETTING_SUCCESS',
    UPDATE_USER_SETTING_FAILURE: 'UPDATE_USER_SETTING_FAILURE',
}

const adminBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL

export const fetchUserSettings = (userId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_USER_SETTINGS_REQUEST, userId })
    try {
        await fetch(adminBaseUrl + 'fetchUserSettings/' + userId, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const configuration = data.payload.configuration
                const languages = data.payload.languages
                dispatch({ 
                    type: actionTypes.FETCH_USER_SETTINGS_SUCCESS, 
                    languages: languages,
                    configuration: configuration, 
                })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_USER_SETTINGS_FAILURE })
        dispatch(setNotification('Error recogiendo la configuración del usuario', 'error'))
    }
}

export const resetUserSettings = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_USER_SETTINGS })
}

export const updateUserSettings = (selectedUser, updateUserSettingsForm) => async (dispatch, getState) => {
    const userId = selectedUser.id
    dispatch({ type: actionTypes.UPDATE_USER_SETTING_REQUEST, userId })
    try {
        await fetch(adminBaseUrl + 'updateUserSettings/' + userId, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateUserSettingsForm),
        })
            .then((response) => response.json())
            .then((data) => {
                const configuration = data.payload.configuration
                dispatch({ 
                    type: actionTypes.UPDATE_USER_SETTING_SUCCESS, 
                    configuration: configuration, 
                })
            })
    } catch (error) {
        dispatch({ type: actionTypes.UPDATE_USER_SETTING_FAILURE })
        dispatch(setNotification('Error actualizando la configuración del usuario', 'error'))
    }
}
