export const actionTypes = {
    FETCH_ABILITIES_REQUEST: 'FETCH_ABILITIES_REQUEST',
    FETCH_ABILITIES_SUCCESS: 'FETCH_ABILITIES_SUCCESS',
    FETCH_ABILITIES_FAILURE: 'FETCH_ABILITIES_FAILURE',
}

const adminBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL

export const fetchAbilities = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_ABILITIES_REQUEST })
    try {
        await fetch(adminBaseUrl + 'fetchAbilities', {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const abilities = data.payload.abilities
                dispatch({ type: actionTypes.FETCH_ABILITIES_SUCCESS, abilities })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_ABILITIES_FAILURE })
    }
}
