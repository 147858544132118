import { Box, Card, LinearProgress, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import { getCountCreatedWithinLastWeek, getCountDisabledUsers, getCountTotalUsers, getCountUsersWithoutAccess, getLastUpdatedUsername, getLoadingUsers, getUsers } from '../../store/reducers/users'

const UsersInfoSection = () => {
    const users = useSelector(getUsers)
    const countDisabledUsers = useSelector(getCountDisabledUsers)
    const countTotalUsers = useSelector(getCountTotalUsers)
    const countUsersWithoutAccess = useSelector(getCountUsersWithoutAccess)
    const countCreatedWithinLastWeek = useSelector(getCountCreatedWithinLastWeek)
    const lastUpdatedUsername = useSelector(getLastUpdatedUsername)
    const loadingUsers = useSelector(getLoadingUsers)

    return (
        <Card sx={{ mb: '1em', pb: '1em' }}>
            <Typography align='center' variant='h5' sx={{ m: '1em' }}>
                Usuarios
            </Typography>
            {(!loadingUsers && users && users.length > 0) ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField
                        variant='outlined' size='small'
                        label='Total'
                        value={countTotalUsers}
                        sx={{ ml: '1em' }}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                    <TextField
                        variant='outlined' size='small'
                        label='Nuevos'
                        value={countCreatedWithinLastWeek}
                        sx={{ ml: '1em' }}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                    <TextField
                        variant='outlined' size='small'
                        label='Deshabilitados'
                        value={countDisabledUsers}
                        sx={{ ml: '1em' }}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                    <TextField
                        variant='outlined' size='small'
                        label='Sin accesos'
                        value={countUsersWithoutAccess}
                        sx={{ ml: '1em' }}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                    <TextField
                        variant='outlined' size='small'
                        label='Último usuario actualizado'
                        value={lastUpdatedUsername}
                        InputProps={{
                            readOnly: true
                        }}
                        sx={{ marginX: '1em' }}
                        fullWidth
                    />
                </Box>
            ) : (
                <LinearProgress />
            )}
        </Card>
    )
}

export default UsersInfoSection
