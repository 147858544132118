import { actionTypes } from '../actions/apps'

const initialState = {
    loading: false,
    apps: [],
}

export const getLoadingApps = (state) => state.apps.loading
export const getApps = (state) => state.apps.apps

const apps = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_APPS_REQUEST:
        case actionTypes.CREATE_APP_REQUEST:
        case actionTypes.UPDATE_APP_REQUEST:
        case actionTypes.CREATE_TOOL_REQUEST:
        case actionTypes.UPDATE_TOOL_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.FETCH_APPS_SUCCESS:
        case actionTypes.CREATE_APP_SUCCESS:
        case actionTypes.UPDATE_APP_SUCCESS:
        case actionTypes.CREATE_TOOL_SUCCESS:
        case actionTypes.UPDATE_TOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                apps: action.apps,
            }
        case actionTypes.FETCH_APPS_FAILURE:
        case actionTypes.RESET_APPS:
            return {
                ...state,
                loading: false,
                apps: [],
            }
        case actionTypes.UPDATE_APP_FAILURE:
        case actionTypes.CREATE_APP_FAILURE:
        case actionTypes.CREATE_TOOL_FAILURE:
        case actionTypes.UPDATE_TOOL_FAILURE:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}

export default apps
