import { Fragment } from 'react'

import { Cancel, Save } from '@mui/icons-material'
import { Button, Card, CardContent, LinearProgress, Modal, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { object, string } from 'yup'

import { createTool } from '../../store/actions/apps'
import { getLoadingApps } from '../../store/reducers/apps'
import { modalStyle } from '../../styles/styles'

const CreateToolModal = (props) => {
    const { createToolModalOpen, handleCloseCreateToolModal, selectedApp } = props
    const dispatch = useDispatch()
    const loadingApps = useSelector(getLoadingApps)

    const createToolForm = useFormik({
        initialValues: {
            name: '',
            tool_code: '',
            app_id: selectedApp.id,
        },
        validationSchema: object().shape({
            name: string()
                .required('Nombre de herramienta es obligatorio')
                .max(255, 'Nombre de herramienta tiene un máximo de 255 carácteres'),
            tool_code: string()
                .required('Código de herramienta es obligatorio')
                .max(50, 'Código de herramienta tiene un máximo de 50 carácteres'),
        }),
        onSubmit: () => dispatch(createTool(createToolForm)),
        onReset: () => handleCloseCreateToolModal(),
    })

    const handleResetAndCloseModal = () => createToolForm.handleReset()

    return (
        <Modal
            open={createToolModalOpen}
            onClose={handleResetAndCloseModal}
        >
            <Card sx={modalStyle}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Nueva herramienta
                    </Typography>
                </CardContent>
                <CardContent>
                    <Fragment>
                        <TextField
                            variant='outlined' size='small'
                            id='name' label='Nombre'
                            error={createToolForm.touched.name && Boolean(createToolForm.errors.name)}
                            helperText={createToolForm.touched.name && createToolForm.errors.name}
                            value={createToolForm.values.name}
                            onChange={createToolForm.handleChange}
                            fullWidth
                        />
                        <TextField
                            variant='outlined' size='small'
                            id='tool_code' label='Código herramienta'
                            error={createToolForm.touched.tool_code && Boolean(createToolForm.errors.tool_code)}
                            helperText={createToolForm.touched.tool_code && createToolForm.errors.tool_code}
                            value={createToolForm.values.tool_code}
                            onChange={createToolForm.handleChange}
                            fullWidth sx={{ marginTop: '1em' }}
                        />
                    </Fragment>
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='contained' color='error' size='small'
                        onClick={handleResetAndCloseModal}
                        disabled={loadingApps}
                        startIcon={<Cancel />}
                    >
                        Cerrar
                    </Button>
                    <Button
                        variant='contained' color='primary' size='small'
                        onClick={createToolForm.handleSubmit}
                        sx={{ marginLeft: '1em' }}
                        disabled={!createToolForm.dirty || loadingApps}
                        startIcon={<Save />}
                    >
                        Guardar
                    </Button>
                </CardContent>
                {loadingApps && (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                )}
            </Card>
        </Modal>
    )
}

export default CreateToolModal
