import { Cancel, Delete } from '@mui/icons-material'
import { Button, Card, CardContent, LinearProgress, Modal, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { deleteAccessData } from '../../store/actions/accessData'
import { getLoadingUsers } from '../../store/reducers/users'
import { modalStyle } from '../../styles/styles'

const DeleteAccessDataModal = (props) => {
    const { deleteAccessDataModalOpen, handleCloseDeleteAccessDataModal, selectedAccessData } = props
    const dispatch = useDispatch()
    const loadingUsers = useSelector(getLoadingUsers)

    const handleDeleteAccessData = () => dispatch(deleteAccessData(selectedAccessData.id)).then(() => handleCloseModal())
    const handleCloseModal = () => handleCloseDeleteAccessDataModal()

    return (
        <Modal
            open={deleteAccessDataModalOpen}
            onClose={handleCloseModal}
        >
            <Card sx={modalStyle}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Borrar dato de acceso
                    </Typography>
                </CardContent>
                <CardContent>
                    <Typography align='center'>¿Está seguro de que desea eliminar el dato de acceso</Typography>
                    <Typography align='center' component='div' variant='h6'>{selectedAccessData.company + ' / ' + selectedAccessData.customer + ' / ' + selectedAccessData.contract + ' / ' + selectedAccessData.service}</Typography>
                    <Typography align='center'>?</Typography>
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='contained'
                        color='error' size='small'
                        onClick={handleCloseDeleteAccessDataModal}
                        disabled={loadingUsers}
                        startIcon={<Cancel />}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant='contained'
                        color='primary' size='small'
                        onClick={handleDeleteAccessData}
                        sx={{ marginLeft: '1em' }}
                        disabled={loadingUsers}
                        startIcon={<Delete />}
                    >
                        Confirmar
                    </Button>
                </CardContent>
                {loadingUsers && (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                )}
            </Card>
        </Modal>
    )
}

export default DeleteAccessDataModal
