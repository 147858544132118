import { createContext, forwardRef, useContext, useRef, useEffect } from 'react'

import { TextField, useTheme, Autocomplete, useMediaQuery, Popper, Typography, styled, autocompleteClasses } from '@mui/material'
import { VariableSizeList } from 'react-window'

const LISTBOX_PADDING = 8

function renderRow(props) {
    const { data, index, style } = props
    const dataSet = data[index]
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    }
    return (
        <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[0].key}
        </Typography>
    )
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data) {
    const ref = useRef(null)
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true)
        }
    }, [data])
    return ref
}

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props
    const itemData = []
    children.forEach((item) => {
        itemData.push(item)
        itemData.push(...(item.children || []))
    })

    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    })
    const itemCount = itemData.length
    const itemSize = smUp ? 36 : 48

    const getChildSize = (child) => {
        return itemSize
    }

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
    }

    const gridRef = useResetCache(itemCount)

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width='100%'
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType='ul'
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    )
})

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
})

const VirtualizedAutocomplete = (props) => {
    const { options, loading, sx, form, fieldName, label, helperText, onChange } = props
    return (
        <Autocomplete
            id='virtualized-autocomplete'
            options={options}
            loading={loading}
            sx={sx}
            onChange={onChange}
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            renderInput={(params) => <TextField
                {...params}
                label={label}
                variant='outlined'
                size='small'
                error={form.touched[fieldName] && Boolean(form.errors[fieldName])}
                helperText={(form.touched[fieldName] && form.errors[fieldName]) || (!form.values[fieldName] && helperText)}
            />}
            renderOption={(props, option, state) => [props, option, state.index]}
        />
    )
}

export default VirtualizedAutocomplete
