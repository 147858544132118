import { actionTypes } from '../actions/notifications'

const initialState = {
    show: false,
    message: '',
    severity: 'success',
}

export const getShowNotifications = (state) => state.notifications.show
export const getMessage = (state) => state.notifications.message
export const getSeverity = (state) => state.notifications.severity

const notifications = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTIFICATION:
            return {
                ...state,
                show: action.show,
                severity: action.severity,
                message: action.message,
            }
        default:
            return state
    }
}

export default notifications
