import { setNotification } from './notifications'

export const actionTypes = {
    FETCH_APPS_REQUEST: 'FETCH_APPS_REQUEST',
    FETCH_APPS_SUCCESS: 'FETCH_APPS_SUCCESS',
    FETCH_APPS_FAILURE: 'FETCH_APPS_FAILURE',
    RESET_APPS: 'RESET_APPS',
    CREATE_APP_REQUEST: 'CREATE_APP_REQUEST',
    CREATE_APP_SUCCESS: 'CREATE_APP_SUCCESS',
    CREATE_APP_FAILURE: 'CREATE_APP_FAILURE',
    DELETE_APP_REQUEST: 'DELETE_APP_REQUEST',
    DELETE_APP_SUCCESS: 'DELETE_APP_SUCCESS',
    DELETE_APP_FAILURE: 'DELETE_APP_FAILURE',
    UPDATE_APP_REQUEST: 'UPDATE_APP_REQUEST',
    UPDATE_APP_SUCCESS: 'UPDATE_APP_SUCCESS',
    UPDATE_APP_FAILURE: 'UPDATE_APP_FAILURE',
    CREATE_TOOL_REQUEST: 'CREATE_TOOL_REQUEST',
    CREATE_TOOL_SUCCESS: 'CREATE_TOOL_SUCCESS',
    CREATE_TOOL_FAILURE: 'CREATE_TOOL_FAILURE',
    UPDATE_TOOL_REQUEST: 'UPDATE_TOOL_REQUEST',
    UPDATE_TOOL_SUCCESS: 'UPDATE_TOOL_SUCCESS',
    UPDATE_TOOL_FAILURE: 'UPDATE_TOOL_FAILURE',
}

const adminBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL

export const fetchApps = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_APPS_REQUEST })
    try {
        await fetch(adminBaseUrl + 'fetchApps', {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const apps = data.payload.apps
                dispatch({ type: actionTypes.FETCH_APPS_SUCCESS, apps })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_APPS_FAILURE })
        dispatch(setNotification('Error recogiendo aplicaciones', 'error'))
    }
}

export const resetApps = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_APPS })
}

export const createApp = (createAppForm) => async (dispatch, getState) => {
    const name = createAppForm.values.name
    const public_key = createAppForm.values.public_key
    const data = { name, public_key }
    dispatch({ type: actionTypes.CREATE_APP_REQUEST, data })
    try {
        await fetch(adminBaseUrl + 'createApp', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.success && data.errors) {
                    dispatch({ type: actionTypes.CREATE_APP_FAILURE })
                    dispatch(setNotification('Error creando aplicación', 'error'))
                    createAppForm.setErrors(data.errors)
                } else if (data.success && data.payload && data.payload.apps) {
                    const apps = data.payload.apps
                    dispatch({ type: actionTypes.CREATE_APP_SUCCESS, apps })
                    dispatch(setNotification('Aplicación ha sido creado', 'success'))
                    createAppForm.handleReset()
                }
            })
    } catch (error) {
        dispatch({ type: actionTypes.CREATE_APP_FAILURE })
        dispatch(setNotification('Error creando aplicación', 'error'))
    }
}

export const updateApp = (updateAppForm) => async (dispatch, getState) => {
    const appId = updateAppForm.values.id
    const name = updateAppForm.values.name
    const public_key = updateAppForm.values.public_key
    const data = { name, public_key }
    dispatch({ type: actionTypes.UPDATE_APP_REQUEST, appId, data })
    try {
        await fetch(adminBaseUrl + 'updateApp/' + appId, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.success && data.errors) {
                    dispatch({ type: actionTypes.UPDATE_APP_FAILURE })
                    dispatch(setNotification('Error modificando aplicación', 'error'))
                    updateAppForm.setErrors(data.errors)
                } else if (data.success && data.payload && data.payload.apps) {
                    const apps = data.payload.apps
                    dispatch({ type: actionTypes.UPDATE_APP_SUCCESS, apps })
                    dispatch(setNotification('Aplicación ha sido modificado', 'success'))
                    updateAppForm.handleReset()
                }
            })
    } catch (error) {
        dispatch({ type: actionTypes.UPDATE_APP_FAILURE })
        dispatch(setNotification('Error modificando aplicación', 'error'))
    }
}

export const createTool = (createToolForm) => async (dispatch, getState) => {
    const app_id = createToolForm.values.app_id
    const name = createToolForm.values.name
    const tool_code = createToolForm.values.tool_code
    const data = { app_id, name, tool_code }
    dispatch({ type: actionTypes.CREATE_TOOL_REQUEST, data })
    try {
        await fetch(adminBaseUrl + 'createTool', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.success && data.errors) {
                    dispatch({ type: actionTypes.CREATE_TOOL_FAILURE })
                    dispatch(setNotification('Error creando herramienta', 'error'))
                    createToolForm.setErrors(data.errors)
                } else if (data.success && data.payload && data.payload.apps) {
                    const apps = data.payload.apps
                    dispatch({ type: actionTypes.CREATE_TOOL_SUCCESS, apps })
                    dispatch(setNotification('Herramienta ha sido creado', 'success'))
                    createToolForm.handleReset()
                }
            })
    } catch (error) {
        dispatch({ type: actionTypes.CREATE_TOOL_FAILURE })
        dispatch(setNotification('Error creando herramienta', 'error'))
    }
}

export const updateTool = (updateToolForm) => async (dispatch, getState) => {
    const toolId = updateToolForm.values.id
    const name = updateToolForm.values.name
    const tool_code = updateToolForm.values.tool_code
    const data = { name, tool_code }
    dispatch({ type: actionTypes.UPDATE_TOOL_REQUEST, toolId, data })
    try {
        await fetch(adminBaseUrl + 'updateTool/' + toolId, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.success && data.errors) {
                    dispatch({ type: actionTypes.UPDATE_TOOL_FAILURE })
                    dispatch(setNotification('Error modificando herramienta', 'error'))
                    updateToolForm.setErrors(data.errors)
                } else if (data.success && data.payload && data.payload.apps) {
                    const apps = data.payload.apps
                    dispatch({ type: actionTypes.UPDATE_TOOL_SUCCESS, apps })
                    dispatch(setNotification('Herramienta ha sido modificado', 'success'))
                    updateToolForm.handleReset()
                }
            })
    } catch (error) {
        dispatch({ type: actionTypes.UPDATE_TOOL_FAILURE })
        dispatch(setNotification('Error modificando herramienta', 'error'))
    }
}
