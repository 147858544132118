import { actionTypes } from '../actions/roles'

const initialState = {
    loadingPoseidonRoles: false,
    loadingRoleAssociations: false,
    loadingRoles: false,
    poseidonRoles: [],
    roleAssociations: [],
    roles: [],
}

export const getLoadingPoseidonRoles = (state) => state.roles.loadingPoseidonRoles
export const getLoadingRoleAssociations = (state) => state.roles.loadingRoleAssociations
export const getLoadingRoles = (state) => state.roles.loadingRoles
export const getPoseidonRoles = (state) => state.roles.poseidonRoles
export const getRoleAssociations = (state) => state.roles.roleAssociations
export const getRoles = (state) => state.roles.roles

const roles = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_POSEIDON_ROLES_REQUEST:
            return {
                ...state,
                loadingPoseidonRoles: true,
            }
        case actionTypes.FETCH_ROLES_REQUEST:
            return {
                ...state,
                loadingRoles: true,
            }

        case actionTypes.FETCH_ROLE_ASSOCIATIONS_REQUEST:
            return {
                ...state,
                loadingRoleAssociations: true,
            }
        case actionTypes.CREATE_ROLE_ASSOCIATION_REQUEST:
            return {
                ...state,
                loadingRoleAssociations: true,
            }
        case actionTypes.DELETE_ROLE_ASSOCIATION_REQUEST:
            return {
                ...state,
                loadingRoleAssociations: true,
            }
        case actionTypes.FETCH_POSEIDON_ROLES_SUCCESS:
            return {
                ...state,
                loadingPoseidonRoles: false,
                poseidonRoles: action.poseidonRoles,
            }
        case actionTypes.FETCH_ROLE_ASSOCIATIONS_SUCCESS:
            return {
                ...state,
                loadingRoleAssociations: false,
                roleAssociations: action.roleAssociations,
            }
        case actionTypes.FETCH_ROLES_SUCCESS:
            return {
                ...state,
                loadingRoles: false,
                roles: action.roles,
            }
        case actionTypes.CREATE_ROLE_ASSOCIATION_SUCCESS:
            return {
                ...state,
                loadingRoleAssociations: false,
                roleAssociations: action.roleAssociations,
            }
        case actionTypes.DELETE_ROLE_ASSOCIATION_SUCCESS:
            return {
                ...state,
                loadingRoleAssociations: false,
                roleAssociations: action.roleAssociations,
            }
        case actionTypes.FETCH_ROLES_FAILURE:
            return {
                ...state,
                loadingRoles: false,
                roles: [],
            }
        case actionTypes.RESET_ROLES:
            return {
                ...state,
                roles: [],
            }
        case actionTypes.FETCH_POSEIDON_ROLES_FAILURE:
            return {
                ...state,
                loadingPoseidonRoles: false,
                poseidonRoles: [],
            }
        case actionTypes.RESET_POSEIDON_ROLES:
            return {
                ...state,
                poseidonRoles: [],
            }
        case actionTypes.FETCH_ROLE_ASSOCIATIONS_FAILURE:
        case actionTypes.RESET_ROLE_ASSOCIATIONS:
            return {
                ...state,
                loadingRoleAssociations: false,
                roleAssociations: [],
            }
        case actionTypes.CREATE_ROLE_ASSOCIATION_FAILURE:
        case actionTypes.DELETE_ROLE_ASSOCIATION_FAILURE:
            return {
                ...state,
                loadingRoleAssociations: false,
            }
        default:
            return state
    }
}

export default roles
