import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router'
import router from './router'
import store from './store/store'

function App() {
  const [cookies] = useCookies([''])

  // When no poseidon token cookie is found, redirect to poseidon page for login
  useEffect(() => {
    if (!cookies.poseidon_token) {
      window.location.replace(process.env.REACT_APP_POSEIDON_REDIRECT_URL)
    }
  })

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  )
}

export default App
