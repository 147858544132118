import { Fragment, useState } from 'react'

import { AddCircleOutline, Cancel, Save, Settings } from '@mui/icons-material'
import { Box, Button, Card, CardContent, darken, Grid, LinearProgress, Modal, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import { useDispatch, useSelector } from 'react-redux'
import { object, string } from 'yup'

import CreateToolModal from './CreateToolModal'
import UpdateToolModal from './UpdateToolModal'
import { updateApp } from '../../store/actions/apps'
import { getApps, getLoadingApps } from '../../store/reducers/apps'
import { getCanCreateTools, getCanUpdateTools } from '../../store/reducers/abilities'
import { bigModalStyle, tableModalStyle } from '../../styles/styles'

const UpdateAppModal = (props) => {
    const { updateAppModalOpen, handleCloseUpdateAppModal, selectedApp } = props
    const [createToolModalOpen, setCreateToolModalOpen] = useState(false)
    const [updateToolModalOpen, setUpdateToolModalOpen] = useState(false)
    const [selectedTool, setSelectedTool] = useState(null)
    const theme = useTheme()
    const dispatch = useDispatch()
    const canCreateTools = useSelector(getCanCreateTools)
    const canUpdateTools = useSelector(getCanUpdateTools)
    const loadingApps = useSelector(getLoadingApps)
    const apps = useSelector(getApps)

    const columns = [
        {
            accessorKey: 'id',
            header: 'Id',
            size: 20,
        },
        {
            accessorKey: 'name',
            header: 'Nombre',
            filterVariant: 'multi-select',
            filterSelectOptions: selectedApp.tools.map((tool) => tool.name),
            size: 80,
        },
        {
            accessorKey: 'tool_code',
            header: 'Código herramienta',
            filterVariant: 'multi-select',
            filterSelectOptions: selectedApp.tools.map((tool) => tool.tool_code),
        },
        {
            id: 'Actions',
            header: 'Acciones',
            Cell: (props) => <Fragment>
                {canUpdateTools && (
                    <Tooltip title='Modificar herramienta'>
                        <Button
                            variant='contained'
                            startIcon={<Settings />}
                            onClick={() => handleOpenUpdateToolModal(props.row.original)}
                            color='warning'
                            size='small'
                        >
                            Modificar
                        </Button>
                    </Tooltip>
                )}
            </Fragment>,
            size: 20,
        },
    ]

    const updateAppForm = useFormik({
        initialValues: {
            id: selectedApp.id,
            name: selectedApp.name,
            public_key: selectedApp.public_key,
        },
        validationSchema: object().shape({
            id: string()
                .required('ID de aplicación es obligatoria'),
            name: string()
                .required('Nombre de aplicación es obligatorio')
                .max(255, 'Nombre de aplicación tiene un máximo de 255 carácteres'),
            public_key: string()
                .required('Clave de aplicación es obligatoria')
                .max(255, 'Clave de aplicación tiene un máximo de 255 carácteres'),
        }),
        onSubmit: () => dispatch(updateApp(updateAppForm)),
        onReset: () => handleCloseUpdateAppModal()
    })

    const handleOpenCreateToolModal = () => setCreateToolModalOpen(true)
    const handleCloseCreateToolModal = () => setCreateToolModalOpen(false)
    const handleOpenUpdateToolModal = (tool) => {
        setSelectedTool(tool)
        setUpdateToolModalOpen(true)
    }
    const handleCloseUpdateToolModal = () => {
        setUpdateToolModalOpen(false)
        setSelectedTool(null)
    }
    const handleResetAndCloseModal = () => updateAppForm.handleReset()

    let selectedAppTools = []
    if (selectedApp) {
        selectedAppTools = apps.filter((apps) => {
            return apps.id === selectedApp.id
        })[0].tools
    }

    return (
        <Fragment>
            <Modal
                open={updateAppModalOpen}
                onClose={handleResetAndCloseModal}
            >
                <Box sx={bigModalStyle}>
                    <Card sx={tableModalStyle}>
                        <CardContent>
                            <Typography align='center' variant='h5'>
                                Configurar aplicación
                            </Typography>
                        </CardContent>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid container item xs={6}>
                                    <TextField
                                        variant='outlined' size='small'
                                        id='name' label='Nombre'
                                        error={updateAppForm.touched.name && Boolean(updateAppForm.errors.name)}
                                        helperText={updateAppForm.touched.name && updateAppForm.errors.name}
                                        value={updateAppForm.values.name}
                                        onChange={updateAppForm.handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid container item xs={6}>
                                    <TextField
                                        variant='outlined' size='small'
                                        id='public_key' label='Clave'
                                        error={updateAppForm.touched.public_key && Boolean(updateAppForm.errors.public_key)}
                                        helperText={updateAppForm.touched.public_key && updateAppForm.errors.public_key}
                                        value={updateAppForm.values.public_key}
                                        onChange={updateAppForm.handleChange}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Typography variant='caption' sx={{ ml: 2 }}>Herramientas</Typography>
                        <CardContent>
                            <MaterialReactTable
                                columns={columns}
                                data={selectedAppTools}
                                initialState={{ columnVisibility: { id: false, tool_code: false }, density: 'compact' }}
                                state={{ isLoading: loadingApps }}
                                localization={MRT_Localization_ES}
                                renderTopToolbarCustomActions={() => canCreateTools ? (
                                    <Tooltip title='Añadir una nueva herramienta'>
                                        <span>
                                            <Button
                                                variant='contained' size='small' color='success'
                                                startIcon={<AddCircleOutline />}
                                                onClick={handleOpenCreateToolModal}
                                                disabled={loadingApps}
                                            >
                                                Nueva herramienta
                                            </Button>
                                        </span>
                                    </Tooltip>
                                )
                                    : null
                                }
                                muiTableBodyProps={{
                                    sx: () => ({
                                        '& tr:nth-of-type(odd)': {
                                            backgroundColor: darken(theme.palette.background.default, 0.05),
                                        },
                                    }),
                                }}
                                muiTableProps={{
                                    sx: {
                                        tableLayout: 'fixed',
                                    },
                                }}
                            />
                        </CardContent>
                        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant='contained' color='error' size='small'
                                onClick={handleResetAndCloseModal}
                                disabled={loadingApps}
                                startIcon={<Cancel />}
                            >
                                Cerrar
                            </Button>
                            <Button
                                variant='contained' color='primary' size='small'
                                onClick={updateAppForm.handleSubmit}
                                sx={{ marginLeft: '1em' }}
                                disabled={!updateAppForm.dirty || loadingApps}
                                startIcon={<Save />}
                            >
                                Guardar
                            </Button>
                        </CardContent>
                        {loadingApps && (
                            <CardContent>
                                <LinearProgress />
                            </CardContent>
                        )}
                    </Card>
                </Box>
            </Modal>
            {createToolModalOpen && (
                <CreateToolModal
                    createToolModalOpen={createToolModalOpen}
                    handleCloseCreateToolModal={handleCloseCreateToolModal}
                    selectedApp={selectedApp}
                />
            )}
            {updateToolModalOpen && (
                <UpdateToolModal
                    updateToolModalOpen={updateToolModalOpen}
                    handleCloseUpdateToolModal={handleCloseUpdateToolModal}
                    selectedApp={selectedApp}
                    selectedTool={selectedTool}
                />
            )}
        </Fragment>
    )
}

export default UpdateAppModal
