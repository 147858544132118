import { Fragment, useEffect, useState } from 'react'

import { Button, Chip, darken, Tooltip, useTheme } from '@mui/material'
import { AddCircleOutline, Settings } from '@mui/icons-material'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import { HeadProvider, Link, Meta, Title } from 'react-head'
import { useDispatch, useSelector } from 'react-redux'

import CreateAppModal from '../components/apps/CreateAppModal'
import UpdateAppModal from '../components/apps/UpdateAppModal'
import { fetchAbilities } from '../store/actions/abilities'
import { fetchApps, resetApps } from '../store/actions/apps'
import { setNotification } from '../store/actions/notifications'
import { getApps, getLoadingApps } from '../store/reducers/apps'
import { getCanCreateApps, getCanManageApps, getCanUpdateApps, getLoadingAbilities } from '../store/reducers/abilities'


const Apps = () => {
    const [selectedApp, setSelectedApp] = useState(null)
    const [createAppModalOpen, setCreateAppModalOpen] = useState(false)
    const [updateAppModalOpen, setUpdateAppModalOpen] = useState(false)
    const [abilitiesFetched, setAbilitiesFetched] = useState(false)
    const theme = useTheme()
    const dispatch = useDispatch()
    const canManageApps = useSelector(getCanManageApps)
    const canCreateApps = useSelector(getCanCreateApps)
    const canUpdateApps = useSelector(getCanUpdateApps)
    const loadingAbilities = useSelector(getLoadingAbilities)
    const loadingApps = useSelector(getLoadingApps)
    const apps = useSelector(getApps)
    let toolOptions = []
    apps.forEach((app) => {
        app.tools.forEach((tool) => {
            toolOptions.push(tool.name)
        })
    })

    const columns = [
        {
            accessorKey: 'id',
            header: 'Id',
            size: 5,
        },
        {
            accessorKey: 'name',
            header: 'Nombre',
            filterVariant: 'multi-select',
            filterSelectOptions: apps.map((app) => app.name),
            size: 60,
        },
        {
            accessorKey: 'public_key',
            header: 'Clave',
            filterVariant: 'multi-select',
            filterSelectOptions: apps.map((app) => app.public_key),
            size: 60,

        },
        {
            header: 'Herramientas',
            Cell: (props) => {
                const tools = props.row.original.tools
                return tools.map((tool) => <Chip label={tool.name} color='primary' key={tool.id} sx={{ m: '2px' }} />)
            },
            accessorFn: (row) => row.tools.toString(),
            enableColumnFilter: true,
            filterVariant: 'select',
            filterSelectOptions: toolOptions,
            filterFn: (row, id, filterValue) => {
                const tools = row.original.tools.map((tool) => tool.name)
                return tools.includes(filterValue.toString())
            },
        },
        {
            header: 'Acciones',
            Cell: (props) => {
                return (
                    <Fragment>
                        {canUpdateApps && (
                            <Tooltip title='Modificar lista de herramientas'>
                                <Button
                                    variant='contained' color='warning' size='small'
                                    startIcon={<Settings />}
                                    onClick={() => handleOpenUpdateAppModal(props.row.original)}
                                >
                                    Modificar
                                </Button>
                            </Tooltip>
                        )}
                    </Fragment>
                )
            },
            size: 20,
        },
    ]

    const handleOpenCreateAppModal = () => setCreateAppModalOpen(true)
    const handleCloseCreateAppModal = () => setCreateAppModalOpen(false)
    const handleOpenUpdateAppModal = (application) => {
        setSelectedApp(application)
        setUpdateAppModalOpen(true)
    }
    const handleCloseUpdateAppModal = () => {
        setUpdateAppModalOpen(false)
        setSelectedApp(null)
    }

    useEffect(() => {
        dispatch(fetchAbilities()).then(() => setAbilitiesFetched(true))
    }, [dispatch])

    useEffect(() => {
        if (abilitiesFetched) {
            if (canManageApps) {
                dispatch(fetchApps())
            } else {
                dispatch(setNotification('No tiene permisos para ver esta sección, contacte con un administrador', 'error'))
            }
            return function cleanup() {
                dispatch(resetApps())
            }
        }
    }, [dispatch, abilitiesFetched, canManageApps])

    return (!loadingAbilities && canManageApps) &&
        <Fragment>
            <HeadProvider>
                <Title>Portal del Cliente Admin | Apps</Title>
                <Link rel='icon' href='public/favicon.ico' />
                <Meta name='description' content='Page for application management' />
            </HeadProvider>
            <MaterialReactTable
                columns={columns}
                data={apps}
                initialState={{ columnVisibility: { id: false, public_key: false }, density: 'compact', pagination: { pageSize: 20 } }}
                state={{ isLoading: loadingApps }}
                localization={MRT_Localization_ES}
                renderTopToolbarCustomActions={() => canCreateApps ?
                    <Tooltip title='Crear una nueva aplicación'>
                        <span>
                            <Button
                                variant='contained' color='success' size='small'
                                startIcon={<AddCircleOutline />}
                                onClick={handleOpenCreateAppModal}
                                disabled={loadingApps}
                            >
                                Nueva app
                            </Button>
                        </span>
                    </Tooltip>
                    : null
                }
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: darken(theme.palette.background.default, 0.05),
                        },
                    }),
                }}
                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}
            />
            {createAppModalOpen && (
                <CreateAppModal
                    createAppModalOpen={createAppModalOpen}
                    handleCloseCreateAppModal={handleCloseCreateAppModal}
                />
            )}
            {updateAppModalOpen && (
                <UpdateAppModal
                    updateAppModalOpen={updateAppModalOpen}
                    handleCloseUpdateAppModal={handleCloseUpdateAppModal}
                    selectedApp={selectedApp}
                />
            )}
        </Fragment>
}

export default Apps
