import { Fragment, useEffect, useState, useRef } from 'react'

import { AddCircleOutline, Delete } from '@mui/icons-material'
import { Box, Button, Card, CardContent, darken, Modal, Tooltip, Typography, useTheme } from '@mui/material'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import { useSelector } from 'react-redux'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import CreateAccessDataModal from './CreateAccessDataModal'
import CopyAccessDataModal from './CopyAccessDataModal'
import DeleteAccessDataModal from './DeleteAccessDataModal'
import { getCanCreateAccessData, getCanDeleteAccessData } from '../../store/reducers/abilities'
import { getLoadingUsers } from '../../store/reducers/users'
import { bigModalStyle, tableModalStyle } from '../../styles/styles'

const ConfigureUserAccessDataModal = (props) => {
    const { configureUserAccessDataModalOpen, handleCloseConfigureUserAccessDataModal, selectedUser, users } = props
    const [createAccessDataModalOpen, setCreateAccessDataModalOpen] = useState(false)
    const [copyAccessDataModalOpen, setCopyAccessDataModalOpen] = useState(false)
    const [deleteAccessDataModalOpen, setDeleteAccessDataModalOpen] = useState(false)
    const [selectedAccessData, setSelectedAccessData] = useState(null)
    const [accessData, setAccessData] = useState([])
    const theme = useTheme()
    const loadingUsers = useSelector(getLoadingUsers)
    const canCreateAccessData = useSelector(getCanCreateAccessData)
    const canDeleteAccessData = useSelector(getCanDeleteAccessData)
    const columns = [
        {
            accessorKey: 'id',
            header: 'Id',
            size: 20,
        },
        {
            header: 'Empresa',
            accessorKey: 'company',
            size: 80,
        },
        {
            header: 'Cliente',
            accessorKey: 'customer',
            size: 80,
        },
        {
            header: 'Contrato',
            accessorKey: 'contract',
            size: 20,
        },
        {
            header: 'Servicio',
            accessorKey: 'service',
            size: 20,
        },
        {
            header: 'Acciones',
            size: 5,
            Cell: (props) => {
                return (
                    <Fragment>
                        {canDeleteAccessData && (
                            <Tooltip title='Borrar dato de acceso'>
                                <Button
                                    variant='contained'
                                    startIcon={<Delete />}
                                    onClick={() => handleOpenDeleteAccessDataModal(props.row.original)}
                                    color='error'
                                    size='small'
                                >
                                    Borrar
                                </Button>
                            </Tooltip>
                        )}
                    </Fragment>
                )
            },
        },
    ]

    const handleCloseModal = () => handleCloseConfigureUserAccessDataModal()
    const handleOpenCreateAccessDataModal = () => setCreateAccessDataModalOpen(true)
    const handleOpenCopyAccessDataModal = () => setCopyAccessDataModalOpen(true)

    const handleCloseCreateAccessDataModal = () => setCreateAccessDataModalOpen(false)
    const handleCloseCopyAccessDataModal = () => setCopyAccessDataModalOpen(false)

    const handleOpenDeleteAccessDataModal = (accessData) => {
        setSelectedAccessData(accessData)
        setDeleteAccessDataModalOpen(true)
    }
    const handleCloseDeleteAccessDataModal = () => {
        setDeleteAccessDataModalOpen(false)
        setSelectedAccessData(null)
    }

    const prevUsers = useRef(users).current

    useEffect(() => {
        setAccessData(selectedUser.accessData)
    }, [selectedUser])

    useEffect(() => {
        if (users) {
            if (prevUsers !== users) {
                const freshAccessData = users.filter(user => user.id === selectedUser.id)[0].accessData
                setAccessData(freshAccessData)
            }
        }
    }, [users, selectedUser, prevUsers])

    return (
        <Fragment>
            <Modal
                open={configureUserAccessDataModalOpen}
                onClose={handleCloseModal}
                sx={{ paddingTop: '30%' }}
            >
                <Box sx={bigModalStyle}>
                    <Card sx={tableModalStyle}>
                        <CardContent>
                            <Typography align='center' variant='h5'>
                                {'Configurar datos de acceso de ' + selectedUser.username}
                            </Typography>
                        </CardContent>
                        <CardContent>
                            <MaterialReactTable
                                columns={columns}
                                data={accessData}
                                initialState={{ columnVisibility: { id: false, center: false }, density: 'compact' }}
                                state={{ isLoading: loadingUsers }}
                                localization={MRT_Localization_ES}
                                renderTopToolbarCustomActions={() => canCreateAccessData ?
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Tooltip title='Añadir un nuevo dato de acceso'>
                                            <Button
                                                variant='contained' color='success' size='small'
                                                startIcon={<AddCircleOutline />}
                                                onClick={() => handleOpenCreateAccessDataModal(selectedUser)}
                                                disabled={loadingUsers}
                                            >
                                                Nuevo acceso
                                            </Button>
                                        </Tooltip>

                                        <Box sx={{ ml: 1 }}>
                                            <Tooltip title='Copiar datos de acceso de otro usuario'>
                                                <Button
                                                    variant='contained' color='success' size='small'
                                                    startIcon={<ContentCopyIcon />}
                                                    onClick={() => handleOpenCopyAccessDataModal(selectedUser)}
                                                    disabled={loadingUsers}
                                                >
                                                    Copiar accesos
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    : null
                                }
                                muiTableBodyProps={{
                                    sx: () => ({
                                        '& tr:nth-of-type(odd)': {
                                            backgroundColor: darken(theme.palette.background.default, 0.05),
                                        },
                                    }),
                                }}
                                muiTableProps={{
                                    sx: {
                                        tableLayout: 'fixed',
                                    },
                                }}
                            />
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
            {createAccessDataModalOpen && (
                <CreateAccessDataModal
                    createAccessDataModalOpen={createAccessDataModalOpen}
                    handleCloseCreateAccessDataModal={handleCloseCreateAccessDataModal}
                    selectedUser={selectedUser}
                />
            )}
            {copyAccessDataModalOpen && (
                <CopyAccessDataModal
                    copyAccessDataModalOpen={copyAccessDataModalOpen}
                    handleCloseCopyAccessDataModal={handleCloseCopyAccessDataModal}
                    selectedUser={selectedUser}
                    users={users}
                />
            )}
            {deleteAccessDataModalOpen && (
                <DeleteAccessDataModal
                    deleteAccessDataModalOpen={deleteAccessDataModalOpen}
                    handleCloseDeleteAccessDataModal={handleCloseDeleteAccessDataModal}
                    selectedAccessData={selectedAccessData}
                />
            )}
        </Fragment>
    )
}

export default ConfigureUserAccessDataModal
