import { Cancel, Save } from '@mui/icons-material'
import { Autocomplete, Box, Button, Card, CardContent, Modal, TextField, Typography, darken, useTheme, } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { object } from 'yup'

import { copyPermission, fetchCopiedPermissions } from '../../store/actions/permissions'
import { mediumModalStyle } from '../../styles/styles'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import { getCopiedPermissions, getLoadingCopiedPermissions } from '../../store/reducers/permissions'
import { useEffect } from 'react'

const CopyPermissionDataModal = (props) => {
    const { copyPermissionDataModalOpen, handleCloseCopyPermissionDataModal, selectedUser, users } = props
    const theme = useTheme()
    const dispatch = useDispatch()
    const copiedPermissions = useSelector(getCopiedPermissions)
    var isLoading = useSelector(getLoadingCopiedPermissions)

    const columns = [
        {
            accessorKey: 'id',
            header: 'Id',
            size: 20,
        },
        {
            accessorKey: 'tool',
            header: 'Herramienta',
        },
        {
            accessorKey: 'app',
            header: 'Aplicación',
        },
    ]

    const copyPermissionDataForm = useFormik({
        initialValues: {
            user: null,
        },
        validationSchema: object().shape({
            user: object()
                .required('obligatorio'),
        }),
        onSubmit: () => {
            dispatch(copyPermission(selectedUser, copyPermissionDataForm, copiedPermissions))
        },
        onReset: () => handleCloseCopyPermissionDataModal(),
    })

    const handleResetAndCloseModal = () => {
        copyPermissionDataForm.handleReset()
    }

    useEffect(() => {
        if (copyPermissionDataForm.values.user != null) {
            dispatch(fetchCopiedPermissions(copyPermissionDataForm.values.user.id))
        }
    }, [dispatch, copyPermissionDataForm.values.user])

    return (
        <Modal
            open={copyPermissionDataModalOpen}
            onClose={handleResetAndCloseModal}
        >
            <Box sx={{ paddingLeft: '2em', paddingTop: '2em', paddingBottom: '2em', paddingRight: '2em' }}>
                <Card sx={mediumModalStyle}>
                    <CardContent sx={{ paddingTop: '2em' }}>
                        <Typography align='center' variant='h5'>
                            Copiar permisos:
                        </Typography>
                    </CardContent>
                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Autocomplete
                            sx={{ width: '40%' }}
                            options={users}
                            getOptionLabel={(user) => user.username}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Usuario a copiar'
                                    error={copyPermissionDataForm.touched.user && Boolean(copyPermissionDataForm.errors.user)}
                                    helperText={copyPermissionDataForm.touched.user && copyPermissionDataForm.errors.user}
                                />
                            )}
                            onChange={
                                (e, selectedUser) => {
                                    copyPermissionDataForm.setFieldValue('user', selectedUser || null)
                                }
                            }
                            value={copyPermissionDataForm.values.user}
                            size='small'
                        />
                    </CardContent>
                    {copyPermissionDataForm.values.user ? (
                        <CardContent >
                            <MaterialReactTable
                                columns={columns}
                                data={copiedPermissions}
                                initialState={{ columnVisibility: { id: false, center: false }, density: 'compact' }}
                                localization={MRT_Localization_ES}
                                muiTableBodyProps={{
                                    sx: () => ({
                                        '& tr:nth-of-type(odd)': {
                                            backgroundColor: darken(theme.palette.background.default, 0.05),
                                        },
                                    }),
                                }}
                                muiTableProps={{
                                    sx: {
                                        tableLayout: 'fixed',
                                    },
                                }}
                                state={
                                    isLoading= {isLoading}
                                }
                            />
                        </CardContent>
                    ) : null}


                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant='contained' color='error' size='small'
                            onClick={handleResetAndCloseModal}
                            startIcon={<Cancel />}
                        >
                            Cerrar
                        </Button>
                        <Button
                            variant='contained' color='primary' size='small'
                            onClick={copyPermissionDataForm.handleSubmit}
                            sx={{ marginLeft: '1em' }}
                            disabled={!copyPermissionDataForm.dirty || !copyPermissionDataForm.values.user}
                            startIcon={<Save />}
                        >
                            Guardar
                        </Button>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    )
}

export default CopyPermissionDataModal
