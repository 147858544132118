import React, { Fragment, useEffect, useState } from 'react'

import { AddCircleOutline, Delete } from '@mui/icons-material'
import { Button, darken, Tooltip, useTheme } from '@mui/material'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import { HeadProvider, Link, Meta, Title } from 'react-head'
import { useDispatch, useSelector } from 'react-redux'

import CreateAssociationModal from '../components/roles/CreateAssociationModal'
import DeleteAssociationModal from '../components/roles/DeleteAssociationModal'
import { fetchAbilities } from '../store/actions/abilities'
import { setNotification } from '../store/actions/notifications'
import { fetchPoseidonRoles, fetchRoleAssociations, fetchRoles, deleteRoleAssociation, resetPoseidonRoles, resetRoleAssociations, resetRoles } from '../store/actions/roles'
import { getCanCreateRoleAssociations, getCanDeleteRoleAssociatons, getCanManageRoleAssociations, getLoadingAbilities } from '../store/reducers/abilities'
import { getLoadingRoleAssociations, getRoleAssociations, getRoles } from '../store/reducers/roles'

const Roles = () => {
    const [createAssociationModalOpen, setCreateAssociationModalOpen] = useState(false)
    const [deleteAssociationModalOpen, setDeleteAssociationModalOpen] = useState(false)
    const [selectedRoleAssociation, setSelectedRoleAssociation] = useState(null)
    const [abilitiesFetched, setAbilitiesFetched] = useState(false)
    const theme = useTheme()
    const dispatch = useDispatch()
    const canManageRoleAssociations = useSelector(getCanManageRoleAssociations)
    const canCreateRoleAssociations = useSelector(getCanCreateRoleAssociations)
    const canDeleteRoleAssociations = useSelector(getCanDeleteRoleAssociatons)
    const loadingAbilities = useSelector(getLoadingAbilities)
    const loadingRoleAssociations = useSelector(getLoadingRoleAssociations)
    const roleAssociations = useSelector(getRoleAssociations)
    const roles = useSelector(getRoles)
    const configuredPoseidonRoles = roleAssociations.map((roleAssociation) => {
        return roleAssociation.poseidonRole
    })

    const columns = [
        {
            accessorKey: 'id',
            header: 'Pivot ID',
            size: 60,
        },
        {
            accessorKey: 'poseidonRole',
            header: 'Rol Poseidon',
            filterVariant: 'multi-select',
            filterSelectOptions: configuredPoseidonRoles,
        },
        {
            accessorKey: 'role',
            header: 'Rol',
            filterVariant: 'multi-select',
            filterSelectOptions: roles.map((role) => role.label),
        },
        {
            header: 'Acciones',
            Cell: (props) => {
                return (
                    <Fragment>
                        {canDeleteRoleAssociations && (
                            <Tooltip title='Borrar la associación de roles'>
                                <Button
                                    variant='contained'
                                    startIcon={<Delete />}
                                    onClick={() => handleOpenDeleteAssociationModal(props.row.original.id, props.row.original.poseidonRole)}
                                    color='error'
                                    size='small'
                                >
                                    Borrar
                                </Button>
                            </Tooltip>
                        )}
                    </Fragment>
                )
            },
        },
    ]

    const handleOpenCreateAssociationModal = () => {
        dispatch(fetchPoseidonRoles())
        setCreateAssociationModalOpen(true)
    }
    const handleCloseCreateAssociationModal = () => {
        setCreateAssociationModalOpen(false)
        dispatch(resetPoseidonRoles())
    }
    const handleCloseDeleteAssociationModal = () => {
        setDeleteAssociationModalOpen(false)
    }
    const handleOpenDeleteAssociationModal = (id, name) => {
        setSelectedRoleAssociation({
            id: id,
            name: name,
        })
        setDeleteAssociationModalOpen(true)
    }
    const handleDeleteAssociation = (poseidonRoleId) => dispatch(deleteRoleAssociation(poseidonRoleId)).then(() => handleCloseDeleteAssociationModal())

    useEffect(() => {
        dispatch(fetchAbilities()).then(() => setAbilitiesFetched(true))
    }, [dispatch])

    useEffect(() => {
        if (abilitiesFetched) {
            if (canManageRoleAssociations) {
                dispatch(fetchRoleAssociations())
                dispatch(fetchRoles())
            } else {
                dispatch(setNotification('No tiene permisos para ver esta sección, contacte con un administrador', 'error'))
            }
            return function cleanup() {
                dispatch(resetRoles())
                dispatch(resetRoleAssociations())
            }
        }
    }, [dispatch, abilitiesFetched, canManageRoleAssociations])

    return (!loadingAbilities && canManageRoleAssociations) &&
        <Fragment>
            <HeadProvider>
                <Title>Portal del Cliente Admin | Roles</Title>
                <Link rel='icon' href='public/favicon.ico' />
                <Meta name='description' content='Page for roles management' />
            </HeadProvider>
            <MaterialReactTable
                columns={columns}
                data={roleAssociations}
                initialState={{ columnVisibility: { id: false }, density: 'compact', pagination: { pageSize: 20 } }}
                state={{ isLoading: loadingRoleAssociations }}
                localization={MRT_Localization_ES}
                renderTopToolbarCustomActions={() => canCreateRoleAssociations ?
                    <Tooltip title='Crear una nueva asociación de roles'>
                        <span>
                            <Button
                                variant='contained' color='success' size='small'
                                startIcon={<AddCircleOutline />}
                                onClick={handleOpenCreateAssociationModal}
                                disabled={loadingRoleAssociations}
                            >
                                Nueva asociación
                            </Button>
                        </span>
                    </Tooltip>
                    : null
                }
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: darken(theme.palette.background.default, 0.05),
                        },
                    }),
                }}
                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}
            />
            {createAssociationModalOpen && (
                <CreateAssociationModal
                    createAssociationModalOpen={createAssociationModalOpen}
                    handleCloseCreateAssociationModal={handleCloseCreateAssociationModal}
                />
            )}
            {deleteAssociationModalOpen && (
                <DeleteAssociationModal
                    deleteAssociationModalOpen={deleteAssociationModalOpen}
                    handleCloseDeleteAssociationModal={handleCloseDeleteAssociationModal}
                    handleDeleteAssociation={handleDeleteAssociation}
                    selectedRoleAssociation={selectedRoleAssociation}
                />
            )}
        </Fragment>
}

export default Roles
