import { Fragment, useEffect, useState } from 'react'

import { AddCircleOutline, AdminPanelSettings, LockPerson, Mail, Person, PersonOff, Settings } from '@mui/icons-material'
import { Button, darken, Tooltip, Typography, useTheme } from '@mui/material'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import { HeadProvider, Link, Meta, Title } from 'react-head'
import { useDispatch, useSelector } from 'react-redux'

import ChangeUserStatusModal from '../components/users/ChangeUserStatusModal'
import CreateUserModal from '../components/users/CreateUserModal'
import ConfigureUserAccessDataModal from '../components/users/ConfigureUserAccessDataModal'
import ConfigureUserPermissionsModal from '../components/users/ConfigureUserPermissionsModal'
import UserSettingsModal from '../components/users/UserSettingsModal'
import UsersInfoSection from '../components/users/UsersInfoSection'
import UpdateCredentialsModal from '../components/users/UpdateCredentialsModal'
import { fetchAbilities } from '../store/actions/abilities'
import { setNotification } from '../store/actions/notifications'
import { fetchUsers, resetUsers } from '../store/actions/users'
import { getCanCreateUsers, getCanManageAcessData, getCanManageUserPermissions, getCanManageUsers, getCanUpdateUsers, getLoadingAbilities } from '../store/reducers/abilities'
import { getLoadingUsers, getUsers } from '../store/reducers/users'

const Users = () => {
    const [createUserModalOpen, setCreateUserModalOpen] = useState(false)
    const [changeUserStatusModalOpen, setChangeUserStatusModalOpen] = useState(false)
    const [configureUserAccessDataModalOpen, setConfigureUserAccessDataModalOpen] = useState(false)
    const [configureUserPermissionsModalOpen, setConfigureUserPermissionsModalOpen] = useState(false)
    const [configureSettingsModalOpen, setSettingsModalOpen] = useState(false)
    const [updateCredentialsModalOpen, setUpdateCredentialsModalOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const [abilitiesFetched, setAbilitiesFetched] = useState(false)
    const dispatch = useDispatch()
    const theme = useTheme()
    const loadingAbilities = useSelector(getLoadingAbilities)
    const loadingUsers = useSelector(getLoadingUsers)
    const users = useSelector(getUsers)
    const canManageUsers = useSelector(getCanManageUsers)
    const canCreateUsers = useSelector(getCanCreateUsers)
    const canUpdateUsers = useSelector(getCanUpdateUsers)
    const canManageAccessData = useSelector(getCanManageAcessData)
    const canManageUserPermissions = useSelector(getCanManageUserPermissions)

    const userTableColumns = [
        {
            accessorKey: 'id',
            header: 'Id',
            size: 20,
        },
        {
            accessorKey: 'username',
            header: 'Nombre usuario',
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        backgroundColor: row.original.accessData.length === 0 ? 'orange' : null,
                    }}
                >
                    {row.original.username}
                </Typography >

            ),
        },
        {
            accessorKey: 'email',
            header: 'Correo',
        },
        {
            accessorKey: 'updated',
            header: 'Última actualización',
        },
        {
            header: 'Acciones',
            Cell: (props) => {
                const disabled = props.row.original.disabled
                return (
                    <Fragment>
                        {canUpdateUsers && (
                            <Fragment>
                                {canManageAccessData && (
                                    <Tooltip title='Configurar datos de acceso'>
                                        <Button
                                            variant='contained' color='primary' size='small'
                                            startIcon={<LockPerson />}
                                            onClick={() => handleOpenConfigureUserAccessDataModal(props.row.original)}
                                        >
                                            Accesos
                                        </Button>
                                    </Tooltip>
                                )}
                                {canManageUserPermissions && (
                                    <Tooltip title='Configurar permisos de aplicaciones'>
                                        <Button
                                            variant='contained' color='primary' size='small'
                                            startIcon={<AdminPanelSettings />}
                                            onClick={() => handleOpenConfigureUserPermissionsModal(props.row.original)}
                                            sx={{ ml: '1em' }}
                                        >
                                            Permisos
                                        </Button>
                                    </Tooltip>
                                )}
                                <Tooltip title='Configurar opciones del usuario'>
                                    <Button
                                        variant='contained' color='primary' size='small'
                                        startIcon={<Settings />}
                                        onClick={() => handleOpenSettingsModal(props.row.original)}
                                        sx={{ ml: '1em' }}
                                    >
                                        Configuración
                                    </Button>
                                </Tooltip>
                                <Tooltip title='Resetear credenciales de acceso y enviar al usuario por correo'>
                                    <Button
                                        variant='contained' color='secondary' size='small'
                                        startIcon={<Mail />}
                                        onClick={() => handleOpenUpdateCredentialsModal(props.row.original)}
                                        sx={{ ml: '1em' }}
                                    >
                                        Notificar
                                    </Button>
                                </Tooltip>
                                <Tooltip title={disabled ? 'Habilitar el usuario' : 'Deshabilitar el usuario'}>
                                    <Button
                                        variant='contained' size='small'
                                        color={disabled ? 'success' : 'error'}
                                        startIcon={disabled ? <Person /> : <PersonOff />}
                                        onClick={() => handleOpenChangeUserStatusModal(props.row.original)}
                                        sx={{ ml: '1em' }}
                                    >
                                        {disabled ? 'Habilitar' : 'Deshabilitar'}
                                    </Button>
                                </Tooltip>
                            </Fragment>
                        )}
                    </Fragment>
                )
            },
            size: 300,
        },
    ]

    const accessDataTableColumns = [
        {
            accessorKey: 'id',
            header: 'Id',
        },
        {
            header: 'Empresa',
            accessorKey: 'company',
            size: 180,
        },
        {
            header: 'Cliente',
            accessorKey: 'customer',
            size: 180,
        },
        {
            header: 'Contrato',
            accessorKey: 'contract',
            size: 100,
        },
        {
            header: 'Servicio',
            accessorKey: 'service',
            size: 100,
        },
    ]

    const handleOpenCreateUserModal = () => setCreateUserModalOpen(true)
    const handleCloseCreateUserModal = () => setCreateUserModalOpen(false)
    const handleOpenConfigureUserAccessDataModal = (user) => {
        setSelectedUser(user)
        setConfigureUserAccessDataModalOpen(true)
    }
    const handleCloseConfigureUserAccessDataModal = () => {
        setConfigureUserAccessDataModalOpen(false)
        setSelectedUser(null)
    }
    const handleOpenConfigureUserPermissionsModal = (user) => {
        setSelectedUser(user)
        setConfigureUserPermissionsModalOpen(true) // este ejmplo
    }
    const handleCloseConfigureUserPermissionsModal = () => {
        setConfigureUserPermissionsModalOpen(false)
        setSelectedUser(null)
    }
    const handleOpenSettingsModal = (user) => {
        setSelectedUser(user)
        setSettingsModalOpen(true)
    }
    const handleCloseSettingsModal = () => {
        setSettingsModalOpen(false)
        setSelectedUser(null)
    }
    const handleOpenChangeUserStatusModal = (user) => {
        setSelectedUser(user)
        setChangeUserStatusModalOpen(true)
    }
    const handleCloseChangeUserStatusModal = () => {
        setChangeUserStatusModalOpen(false)
        setSelectedUser(null)
    }
    const handleOpenUpdateCredentialsModal = (user) => {
        setSelectedUser(user)
        setUpdateCredentialsModalOpen(true)
    }
    const handleCloseUpdateCredentialsModal = () => {
        setUpdateCredentialsModalOpen(false)
        setSelectedUser(null)
    }

    useEffect(() => {
        dispatch(fetchAbilities()).then(() => setAbilitiesFetched(true))
    }, [dispatch])

    useEffect(() => {
        if (abilitiesFetched) {
            if (canManageUsers) {
                dispatch(fetchUsers())
            } else {
                dispatch(setNotification('No tiene permisos para ver esta sección, contacte con un administrador', 'error'))
            }
            return function cleanup() {
                dispatch(resetUsers())
            }
        }
    }, [dispatch, abilitiesFetched, canManageUsers])

    return (!loadingAbilities && canManageUsers) && (
        <Fragment>
            <HeadProvider>
                <Title>Portal del Cliente Admin | Usuarios</Title>
                <Link rel='icon' href='public/favicon.ico' />
                <Meta name='description' content='Page for user management' />
            </HeadProvider>
            <UsersInfoSection />
            <MaterialReactTable
                columns={userTableColumns}
                data={users}
                initialState={{ columnVisibility: { id: false, email: false, updated: false }, density: 'compact', pagination: { pageSize: 20 } }}
                state={{ isLoading: loadingUsers }}
                localization={MRT_Localization_ES}
                renderTopToolbarCustomActions={() => canCreateUsers ?
                    <Tooltip title='Crear un nuevo usuario'>
                        <span>
                            <Button
                                variant='contained' color='success' size='small'
                                startIcon={<AddCircleOutline />}
                                onClick={handleOpenCreateUserModal}
                                disabled={loadingUsers}
                            >
                                Nuevo usuario
                            </Button>
                        </span>
                    </Tooltip>
                    : null
                }
                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}
                renderDetailPanel={({ row }) => {
                    let accessData = row.original.accessData
                    return (
                        <MaterialReactTable
                            columns={accessDataTableColumns}
                            data={accessData}
                            initialState={{ columnVisibility: { id: false, }, density: 'compact' }}
                            localization={MRT_Localization_ES}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enablePagination={false}
                            enableSorting={false}
                            enableBottomToolbar={false}
                            enableTopToolbar={false}
                            muiTableBodyRowProps={{ hover: false }}
                            muiTableBodyProps={{
                                sx: () => ({
                                    '& tr:nth-of-type(odd)': {
                                        backgroundColor: darken(theme.palette.background.default, 0.05),
                                    },
                                }),
                            }}
                            muiTableProps={{
                                sx: {
                                    tableLayout: 'fixed',
                                },
                            }}
                        />
                    )
                }}
                positionExpandColumn='last'
            />
            {createUserModalOpen && (
                <CreateUserModal
                    createUserModalOpen={createUserModalOpen}
                    handleCloseCreateUserModal={handleCloseCreateUserModal}
                />
            )}
            {configureUserAccessDataModalOpen && (
                <ConfigureUserAccessDataModal
                    configureUserAccessDataModalOpen={configureUserAccessDataModalOpen}
                    handleCloseConfigureUserAccessDataModal={handleCloseConfigureUserAccessDataModal}
                    selectedUser={selectedUser}
                    users={users}
                />
            )}
            {configureUserPermissionsModalOpen && (
                <ConfigureUserPermissionsModal
                    configureUserPermissionsModalOpen={configureUserPermissionsModalOpen}
                    handleCloseConfigureUserPermissionsModal={handleCloseConfigureUserPermissionsModal}
                    selectedUser={selectedUser}
                    users={users}
                />
            )}
            {configureSettingsModalOpen && (
                <UserSettingsModal
                    configureSettingsModalOpen={configureSettingsModalOpen}
                    handleCloseSettingsModal={handleCloseSettingsModal}
                    selectedUser={selectedUser}
                />
            )}
            {changeUserStatusModalOpen && (
                <ChangeUserStatusModal
                    changeUserStatusModalOpen={changeUserStatusModalOpen}
                    handleCloseChangeUserStatusModal={handleCloseChangeUserStatusModal}
                    selectedUser={selectedUser}
                />
            )}
            {updateCredentialsModalOpen && (
                <UpdateCredentialsModal
                    updateCredentialsModalOpen={updateCredentialsModalOpen}
                    handleCloseUpdateCredentialsModal={handleCloseUpdateCredentialsModal}
                    selectedUser={selectedUser}
                />
            )}
        </Fragment>
    )
}

export default Users
