import { useEffect } from 'react'

import { Cancel, Save } from '@mui/icons-material'
import { Autocomplete, Button, Card, CardContent, LinearProgress, Modal, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { object } from 'yup'

import VirtualizedAutocomplete from '../VirtualizedAutoComplete'
import { createAccessData, fetchCompaniesAndCustomers, fetchContracts, fetchServices, resetCompaniesAndCustomers, resetContracts, resetServices } from '../../store/actions/accessData'
import { getCompanies, getContracts, getCustomers, getloadingCompaniesAndCustomers, getLoadingContracts, getLoadingServices, getServices } from '../../store/reducers/accessData'
import { getLoadingUsers } from '../../store/reducers/users'
import { modalStyle } from '../../styles/styles'

const CreateAccessDataModal = (props) => {
    const { createAccessDataModalOpen, handleCloseCreateAccessDataModal, selectedUser } = props
    const loadingCompaniesAndCustomers = useSelector(getloadingCompaniesAndCustomers)
    const companies = useSelector(getCompanies)
    const customers = useSelector(getCustomers)
    const loadingContracts = useSelector(getLoadingContracts)
    const contracts = useSelector(getContracts)
    const loadingServices = useSelector(getLoadingServices)
    const services = useSelector(getServices)
    const dispatch = useDispatch()
    const loadingUsers = useSelector(getLoadingUsers)

    const createAccessDataForm = useFormik({
        initialValues: {
            company: null,
            customer: null,
            contract: null,
            service: null,
        },
        validationSchema: object().shape({
            company: object()
                .required('obligatorio'),
            customer: object()
                .required('obligatorio'),
        }),
        onSubmit: () => dispatch(createAccessData(selectedUser, createAccessDataForm)),
        onReset: () => handleCloseCreateAccessDataModal(),
    })

    useEffect(() => {
        dispatch(fetchCompaniesAndCustomers())
    }, [dispatch])

    const handleResetAndCloseModal = () => {
        createAccessDataForm.handleReset()
        dispatch(resetCompaniesAndCustomers())
        dispatch(resetContracts())
        dispatch(resetServices())
    }

    const companyFieldValue = createAccessDataForm.values.company
    const customerFieldValue = createAccessDataForm.values.customer

    useEffect(() => {
        dispatch(resetContracts())
        dispatch(resetServices())
        if (customerFieldValue !== null && companyFieldValue !== null) {
            const customerId = customerFieldValue.id
            const companyId = companyFieldValue.id
            dispatch(fetchContracts(companyId, customerId))
        }
    }, [dispatch, customerFieldValue, companyFieldValue])

    const contractFieldValue = createAccessDataForm.values.contract

    useEffect(() => {
        dispatch(resetServices())
        if (contractFieldValue) {
            const contractId = contractFieldValue.id
            if (contractId) {
                dispatch(fetchServices(contractId))
            }
        }
    }, [dispatch, contractFieldValue])

    const handleCustomerSelectorChange = (e, selectedCustomer) => {
        createAccessDataForm.setFieldValue('customer', selectedCustomer || null)
        if (!selectedCustomer) {
            createAccessDataForm.setFieldValue('contract', null)
            createAccessDataForm.setFieldValue('service', null)
            dispatch(resetContracts())
            dispatch(resetServices())
        }
    }

    const handleContractSelectorChange = (e, selectedContract) => {
        createAccessDataForm.setFieldValue('contract', selectedContract || null)
        if (!selectedContract) {
            createAccessDataForm.setFieldValue('service', null)
            dispatch(resetServices())
        }
    }

    return (
        <Modal
            open={createAccessDataModalOpen}
            onClose={handleResetAndCloseModal}
        >
            <Card sx={modalStyle}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Nuevo dato de acceso
                    </Typography>
                </CardContent>
                <CardContent>
                    <Autocomplete
                        loading={loadingCompaniesAndCustomers}
                        id='company'
                        options={companies}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='outlined'
                                label='Empresa'
                                error={createAccessDataForm.touched.company && Boolean(createAccessDataForm.errors.company)}
                                helperText={createAccessDataForm.touched.company && createAccessDataForm.errors.company}
                            />
                        )}
                        onChange={(e, selectedCompany) => createAccessDataForm.setFieldValue('company', selectedCompany || null)}
                        value={createAccessDataForm.values.company}
                        size='small'
                    />
                    <VirtualizedAutocomplete
                        options={customers}
                        loading={loadingCompaniesAndCustomers}
                        form={createAccessDataForm}
                        fieldName='customer'
                        sx={{ mt: '10px' }}
                        label='Cliente'
                        onChange={handleCustomerSelectorChange}
                    />
                    {createAccessDataForm.values.customer && (
                        <VirtualizedAutocomplete
                            options={contracts}
                            loading={loadingContracts}
                            form={createAccessDataForm}
                            fieldName='contract'
                            sx={{ mt: '10px' }}
                            label='Contrato'
                            helperText='Valor por defecto: Todos los contratos'
                            onChange={handleContractSelectorChange}
                        />
                    )}
                    {createAccessDataForm.values.contract && (
                        <VirtualizedAutocomplete
                            options={services}
                            loading={loadingServices}
                            form={createAccessDataForm}
                            fieldName='service'
                            sx={{ mt: '10px' }}
                            label='Servicio'
                            helperText='Valor por defecto: Todos los servicios'
                            onChange={(e, selectedService) => createAccessDataForm.setFieldValue('service', selectedService || null)}
                        />
                    )}
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='contained' color='error' size='small'
                        onClick={handleResetAndCloseModal}
                        disabled={loadingUsers}
                        startIcon={<Cancel />}
                    >
                        Cerrar
                    </Button>
                    <Button
                        variant='contained' color='primary' size='small'
                        onClick={createAccessDataForm.handleSubmit}
                        sx={{ marginLeft: '1em' }}
                        disabled={!createAccessDataForm.dirty || loadingUsers || !createAccessDataForm.values.customer}
                        startIcon={<Save />}
                    >
                        Guardar
                    </Button>
                </CardContent>
                {loadingUsers && (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                )}
            </Card>
        </Modal>
    )
}

export default CreateAccessDataModal
