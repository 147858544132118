import { setNotification } from './notifications'

export const actionTypes = {
    FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
    FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
    FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
    RESET_USERS: 'RESET_USERS',
    TOGGLE_USER_STATUS_REQUEST: 'TOGGLE_USER_STATUS_REQUEST',
    TOGGLE_USER_STATUS_SUCCESS: 'TOGGLE_USER_STATUS_SUCCESS',
    TOGGLE_USER_STATUS_FAILURE: 'TOGGLE_USER_STATUS_FAILURE',
    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
    UPDATE_CREDENTIALS_REQUEST: 'UPDATE_CREDENTIALS_REQUEST',
    UPDATE_CREDENTIALS_SUCCESS: 'UPDATE_CREDENTIALS_SUCCESS',
    UPDATE_CREDENTIALS_FAILURE: 'UPDATE_CREDENTIALS_FAILURE',
}

const adminBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL

export const fetchUsers = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_USERS_REQUEST })
    try {
        await fetch(adminBaseUrl + 'fetchUsers', {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const users = data.payload.users
                dispatch({ type: actionTypes.FETCH_USERS_SUCCESS, users })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_USERS_FAILURE })
        dispatch(setNotification('Error recogiendo usuarios', 'error'))
    }
}

export const resetUsers = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_USERS })
}

export const toggleUserStatus = (userId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TOGGLE_USER_STATUS_REQUEST, userId })
    try {
        await fetch(adminBaseUrl + 'toggleUserStatus/' + userId, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const users = data.payload.users
                dispatch({ type: actionTypes.TOGGLE_USER_STATUS_SUCCESS, users })
                dispatch(setNotification('Estado del usuario ha sido cambiado', 'success'))
            })
    } catch (error) {
        dispatch({ type: actionTypes.TOGGLE_USER_STATUS_FAILURE })
        dispatch(setNotification('Error cambiando estado del usuario', 'error'))
    }
}

export const createUser = (createUserForm) => async (dispatch, getState) => {
    const username = createUserForm.values.username
    const data = { username: username, email: username }
    dispatch({ type: actionTypes.CREATE_USER_REQUEST, data })
    try {
        await fetch(adminBaseUrl + 'createUser', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.success && data.errors) {
                    dispatch({ type: actionTypes.CREATE_USER_FAILURE })
                    dispatch(setNotification('Error creando usuario', 'error'))
                    createUserForm.setErrors(data.errors)
                } else if (data.success && data.payload && data.payload.users) {
                    const users = data.payload.users
                    dispatch({ type: actionTypes.CREATE_USER_SUCCESS, users })
                    dispatch(setNotification('Usuario ha sido creado', 'success'))
                    createUserForm.handleReset()
                }
            })
    } catch (error) {
        dispatch({ type: actionTypes.CREATE_USER_FAILURE })
        dispatch(setNotification('Error creando usuario', 'error'))
    }
}

export const updateCredentials = (userId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_CREDENTIALS_REQUEST })
    try {
        await fetch(adminBaseUrl + 'updateCredentials/' + userId, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const users = data.payload.users
                dispatch({ type: actionTypes.UPDATE_CREDENTIALS_SUCCESS, users })
                dispatch(setNotification('Credenciales del usuario han sido actualizado y enviado al cliente', 'success'))
            })
    } catch (error) {
        dispatch({ type: actionTypes.UPDATE_CREDENTIALS_FAILURE })
        dispatch(setNotification('Error actualizando credenciales del usuario', 'error'))
    }
}
