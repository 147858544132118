import { Navigate, createBrowserRouter } from 'react-router-dom'

import Root from './components/Root'
import Apps from './pages/Apps'
import Users from './pages/Users'
import Roles from './pages/Roles'

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: '/',
                element: <Users />,
            },
            {
                path: '/apps',
                element: <Apps />,
            },
            {
                path: '/roles',
                element: <Roles />,
            },
            {
                path: '*',
                element: <Navigate to="/" /> 
              },
        ],
    },
])

export default router
