import { Cancel, Mail } from '@mui/icons-material'
import { Button, Card, CardContent, LinearProgress, Modal, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { updateCredentials } from '../../store/actions/users'
import { getLoadingUsers } from '../../store/reducers/users'
import { modalStyle } from '../../styles/styles'

const UpdateCredentialsModal = (props) => {
    const { updateCredentialsModalOpen, handleCloseUpdateCredentialsModal, selectedUser } = props
    const dispatch = useDispatch()
    const loadingUsers = useSelector(getLoadingUsers)

    const handleCloseModal = () => handleCloseUpdateCredentialsModal()
    const handleUpdateCredentials = () => {
        const userId = selectedUser.id
        dispatch(updateCredentials(userId)).then(() => handleCloseModal())
    }

    return (
        <Modal
            open={updateCredentialsModalOpen}
            onClose={handleCloseModal}
        >
            <Card sx={modalStyle}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Notificar usuario
                    </Typography>
                </CardContent>
                <CardContent>
                    <Typography align='center'>¿Está seguro de que deseas resetear la contraseña</Typography>
                    <Typography align='center'>y enviar los datos de acceso del usuario</Typography>
                    {selectedUser.username !== selectedUser.email && <Typography align='center' component='div' variant='h6'>{selectedUser.username}</Typography>}
                    <Typography align='center'>a su correo electrónico</Typography>
                    <Typography align='center' component='div' variant='h6'>{selectedUser.email}</Typography>
                    <Typography align='center'>?</Typography>
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='contained' color='error' size='small'
                        onClick={handleCloseModal}
                        disabled={loadingUsers}
                        startIcon={<Cancel />}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant='contained' color='primary' size='small'
                        onClick={handleUpdateCredentials}
                        sx={{ marginLeft: '1em' }}
                        disabled={loadingUsers}
                        startIcon={<Mail />}
                    >
                        Confirmar
                    </Button>
                </CardContent>
                {loadingUsers && (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                )}
            </Card>
        </Modal>
    )
}

export default UpdateCredentialsModal
