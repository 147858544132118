import { setNotification } from './notifications'

export const actionTypes = {
    FETCH_ROLE_ASSOCIATIONS_REQUEST: 'FETCH_ROLE_ASSOCIATIONS_REQUEST',
    FETCH_ROLE_ASSOCIATIONS_SUCCESS: 'FETCH_ROLE_ASSOCIATIONS_SUCCESS',
    FETCH_ROLE_ASSOCIATIONS_FAILURE: 'FETCH_ROLE_ASSOCIATIONS_FAILURE',
    RESET_ROLE_ASSOCIATIONS: 'RESET_ROLE_ASSOCIATIONS',
    FETCH_ROLES_REQUEST: 'FETCH_ROLES_REQUEST',
    FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
    FETCH_ROLES_FAILURE: 'FETCH_ROLES_FAILURE',
    RESET_ROLES: 'RESET_ROLES',
    FETCH_POSEIDON_ROLES_REQUEST: 'FETCH_POSEIDON_ROLES_REQUEST',
    FETCH_POSEIDON_ROLES_SUCCESS: 'FETCH_POSEIDON_ROLES_SUCCESS',
    FETCH_POSEIDON_ROLES_FAILURE: 'FETCH_POSEIDON_ROLES_FAILURE',
    RESET_POSEIDON_ROLES: 'RESET_POSEIDON_ROLES',
    CREATE_ROLE_ASSOCIATION_REQUEST: 'CREATE_ROLE_ASSOCIATION_REQUEST',
    CREATE_ROLE_ASSOCIATION_SUCCESS: 'CREATE_ROLE_ASSOCIATION_SUCCESS',
    CREATE_ROLE_ASSOCIATION_FAILURE: 'CREATE_ROLE_ASSOCIATION_FAILURE',
    DELETE_ROLE_ASSOCIATION_REQUEST: 'DELETE_ROLE_ASSOCIATION_REQUEST',
    DELETE_ROLE_ASSOCIATION_SUCCESS: 'DELETE_ROLE_ASSOCIATION_SUCCESS',
    DELETE_ROLE_ASSOCIATION_FAILURE: 'DELETE_ROLE_ASSOCIATION_FAILURE',
}

const adminBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL

export const fetchRoleAssociations = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_ROLE_ASSOCIATIONS_REQUEST })
    try {
        await fetch(adminBaseUrl + 'fetchRoleAssociations', {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const roleAssociations = data.payload.roleAssociations
                dispatch({ type: actionTypes.FETCH_ROLE_ASSOCIATIONS_SUCCESS, roleAssociations })
            })

    } catch (error) {
        dispatch({ type: actionTypes.FETCH_ROLE_ASSOCIATIONS_FAILURE })
        dispatch(setNotification('Error recogiendo asociaciones de roles', 'error'))
    }
}

export const resetRoleAssociations = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_ROLE_ASSOCIATIONS })
}

export const fetchRoles = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_ROLES_REQUEST })
    try {
        await fetch(adminBaseUrl + 'fetchRoles', {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const roles = data.payload.roles
                dispatch({ type: actionTypes.FETCH_ROLES_SUCCESS, roles })
            })

    } catch (error) {
        dispatch({ type: actionTypes.FETCH_ROLES_FAILURE })
        dispatch(setNotification('Error recogiendo roles', 'error'))
    }
}

export const resetRoles = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_ROLES })
}

export const fetchPoseidonRoles = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_POSEIDON_ROLES_REQUEST })
    try {
        await fetch(adminBaseUrl + 'fetchPoseidonRoles', {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const poseidonRoles = data.payload.poseidonRoles
                dispatch({ type: actionTypes.FETCH_POSEIDON_ROLES_SUCCESS, poseidonRoles })
            })

    } catch (error) {
        dispatch({ type: actionTypes.FETCH_POSEIDON_ROLES_FAILURE })
        dispatch(setNotification('Error recogiendo roles poseidon', 'error'))
    }
}

export const resetPoseidonRoles = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_POSEIDON_ROLES })
}

export const createRoleAssociation = (createRoleAssociationForm) => async (dispatch, getState) => {
    const poseidonRoleId = createRoleAssociationForm.values.poseidonRole.id
    const roleId = createRoleAssociationForm.values.role.id
    const data = { poseidonRoleId, roleId }
    dispatch({ type: actionTypes.CREATE_ROLE_ASSOCIATION_REQUEST, data })
    try {
        await fetch(adminBaseUrl + 'createRoleAssociation', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.success && data.errors) {
                    dispatch({ type: actionTypes.CREATE_ROLE_ASSOCIATION_FAILURE })
                    dispatch(setNotification('Error creando asociación de roles', 'error'))
                    createRoleAssociationForm.setErrors(data.errors)
                } else if (data.success && data.payload && data.payload.roleAssociations) {
                    const roleAssociations = data.payload.roleAssociations
                    dispatch({ type: actionTypes.CREATE_ROLE_ASSOCIATION_SUCCESS, roleAssociations })
                    dispatch(setNotification('Rol ha sido asociado', 'success'))
                    createRoleAssociationForm.handleReset()
                }
            })
    } catch (error) {
        dispatch({ type: actionTypes.CREATE_ROLE_ASSOCIATION_FAILURE })
        dispatch(setNotification('Error creando asociación de roles', 'error'))
    }
}

export const deleteRoleAssociation = (poseidonRoleId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.DELETE_ROLE_ASSOCIATION_REQUEST, poseidonRoleId })
    try {
        await fetch(adminBaseUrl + 'deleteRoleAssociation/' + poseidonRoleId, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const roleAssociations = data.payload.roleAssociations
                dispatch({ type: actionTypes.DELETE_ROLE_ASSOCIATION_SUCCESS, roleAssociations })
                dispatch(setNotification('Rol ha sido disacociado', 'success'))
            })
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_ROLE_ASSOCIATION_FAILURE })
        dispatch(setNotification('Error borrando asociación de roles', 'error'))
    }
}
