import { configureStore } from '@reduxjs/toolkit'
import abilitiesReducer from './reducers/abilities'
import accessDataReducer from './reducers/accessData'
import appsReducer from './reducers/apps'
import notificationsReducer from './reducers/notifications'
import permissionsReducer from './reducers/permissions'
import rolesReducer from './reducers/roles'
import usersReducer from './reducers/users'
import userSettings from './reducers/userSettings'

const store = configureStore({
    reducer: {
        abilities: abilitiesReducer,
        accessData: accessDataReducer,
        apps: appsReducer,
        notifications: notificationsReducer,
        permissions: permissionsReducer,
        roles: rolesReducer,
        users: usersReducer,
        userSettings: userSettings,
    }
})

export default store
