import { Cancel, Delete } from '@mui/icons-material'
import { Button, Card, CardContent, LinearProgress, Modal, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { deletePermission } from '../../store/actions/permissions'
import { getLoadingPermissions } from '../../store/reducers/permissions'
import { modalStyle } from '../../styles/styles'

const DeleteUserPermissionModal = (props) => {
    const { deleteUserPermissionModalOpen, handleCloseDeleteUserPermissionModal, selectedUserPermission, selectedUser } = props
    const dispatch = useDispatch()
    const loadingPermissions = useSelector(getLoadingPermissions)

    const handleDeleteUserPermission = () => dispatch(deletePermission(selectedUser.id, selectedUserPermission.id)).then(() => handleCloseModal())
    const handleCloseModal = () => handleCloseDeleteUserPermissionModal()

    return (
        <Modal
            open={deleteUserPermissionModalOpen}
            onClose={handleCloseModal}
        >
            <Card sx={modalStyle}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Borrar permiso de usuario'
                    </Typography>
                </CardContent>
                <CardContent>
                    <Typography align='center'>¿Está seguro de que desea eliminar el acceso a</Typography>
                    <Typography align='center' component='div' variant='h6'>{selectedUserPermission.app + '/' + selectedUserPermission.tool}</Typography>
                    <Typography align='center'>para el usuario</Typography>
                    <Typography align='center' component='div' variant='h6'>{selectedUser.username}{(selectedUser.email !== selectedUser.username) && ' (' + selectedUser.email + ')'}</Typography>
                    <Typography align='center'>?</Typography>
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='contained' color='error' size='small'
                        onClick={handleCloseModal}
                        disabled={loadingPermissions}
                        startIcon={<Cancel />}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant='contained' color='primary' size='small'
                        onClick={handleDeleteUserPermission}
                        sx={{ marginLeft: '1em' }}
                        disabled={loadingPermissions}
                        startIcon={<Delete />}
                    >
                        Confirmar
                    </Button>
                </CardContent>
                {loadingPermissions && (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                )}
            </Card>
        </Modal>
    )
}

export default DeleteUserPermissionModal
