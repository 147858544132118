import { Cancel, Save } from '@mui/icons-material'
import { Autocomplete, Box, Button, Card, CardContent, Modal, TextField, Typography, darken, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { object } from 'yup'

import { copyAccessData } from '../../store/actions/accessData'
import { mediumModalStyle } from '../../styles/styles'
import { useEffect, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'

const CopyAccessDataModal = (props) => {
    const { copyAccessDataModalOpen, handleCloseCopyAccessDataModal, selectedUser, users } = props
    const dispatch = useDispatch()
    const [accessData, setAccessData] = useState([])
    const theme = useTheme()


    const copyAccessDataForm = useFormik({
        initialValues: {
            user: null,
        },
        validationSchema: object().shape({
            user: object()
                .required('obligatorio'),
        }),
        onSubmit: () => dispatch(copyAccessData(selectedUser, copyAccessDataForm)),
        onReset: () => handleCloseCopyAccessDataModal(),
    })
    useEffect(() => {
        if (copyAccessDataForm.values.user != null) {
            setAccessData(copyAccessDataForm.values.user.accessData)

        }
    }, [copyAccessDataForm.values.user])

    const handleResetAndCloseModal = () => {
        copyAccessDataForm.handleReset()
    }
    const columns = [
        {
            accessorKey: 'id',
            header: 'Id',
            size: 20,
        },
        {
            header: 'Empresa',
            accessorKey: 'company',
            size: 80,
        },
        {
            header: 'Cliente',
            accessorKey: 'customer',
            size: 80,
        },
        {
            header: 'Contrato',
            accessorKey: 'contract',
            size: 20,
        },
        {
            header: 'Servicio',
            accessorKey: 'service',
            size: 20,
        },
    ]

    return (
        <Modal
            open={copyAccessDataModalOpen}
            onClose={handleResetAndCloseModal}
        >
            <Box sx={{ paddingLeft: '2em', paddingTop: '2em', paddingBottom: '2em', paddingRight: '2em' }}>
                <Card sx={mediumModalStyle}>
                    <CardContent sx={{ paddingTop: '2em' }}>
                        <Typography align='center' variant='h5'>
                            Copiar accesos:
                        </Typography>
                    </CardContent>
                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Autocomplete
                            sx={{ width: '40%' }}
                            options={users}
                            getOptionLabel={(user) => user.username}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Usuario a copiar'
                                    error={copyAccessDataForm.touched.user && Boolean(copyAccessDataForm.errors.user)}
                                    helperText={copyAccessDataForm.touched.user && copyAccessDataForm.errors.user}
                                />
                            )}
                            onChange={(e, selectedUser) => copyAccessDataForm.setFieldValue('user', selectedUser || null)}
                            value={copyAccessDataForm.values.user}
                            size='small'
                        />
                    </CardContent>
                    {copyAccessDataForm.values.user ? (
                        <CardContent >
                            <MaterialReactTable
                                columns={columns}
                                data={accessData}
                                initialState={{ columnVisibility: { id: false, center: false }, density: 'compact' }}
                                localization={MRT_Localization_ES}
                                muiTableBodyProps={{
                                    sx: () => ({
                                        '& tr:nth-of-type(odd)': {
                                            backgroundColor: darken(theme.palette.background.default, 0.05),
                                        },
                                    }),
                                }}
                                muiTableProps={{
                                    sx: {
                                        tableLayout: 'fixed',
                                    },
                                }}
                            />
                        </CardContent>
                    ) : null}

                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant='contained' color='error' size='small'
                            onClick={handleResetAndCloseModal}
                            startIcon={<Cancel />}
                        >
                            Cerrar
                        </Button>
                        <Button
                            variant='contained' color='primary' size='small'
                            onClick={copyAccessDataForm.handleSubmit}
                            sx={{ marginLeft: '1em' }}
                            disabled={!copyAccessDataForm.dirty || !copyAccessDataForm.values.user}
                            startIcon={<Save />}
                        >
                            Guardar
                        </Button>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    )
}

export default CopyAccessDataModal
