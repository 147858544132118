import { Cancel, Delete } from '@mui/icons-material'
import { Button, Card, CardContent, LinearProgress, Modal, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import { getLoadingRoleAssociations } from '../../store/reducers/roles'
import { modalStyle } from '../../styles/styles'

const DeleteAssociationModal = (props) => {
    const { deleteAssociationModalOpen, handleCloseDeleteAssociationModal, handleDeleteAssociation, selectedRoleAssociation } = props
    const loadingRoleAssociations = useSelector(getLoadingRoleAssociations)

    const handleCloseModal = () => handleCloseDeleteAssociationModal()

    return (
        <Modal
            open={deleteAssociationModalOpen}
            onClose={handleCloseModal}
        >
            <Card sx={modalStyle}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Borrar asociación de rol
                    </Typography>
                </CardContent>
                <CardContent>
                    <Typography align='center'>¿Está seguro de que desea eliminar</Typography>
                    <Typography align='center'>la asociación de roles del rol poseidon</Typography>
                    <Typography align='center' component='div' variant='h6'>{selectedRoleAssociation.name}</Typography>
                    <Typography align='center'>?</Typography>
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='contained' color='error' size='small'
                        onClick={handleCloseDeleteAssociationModal}
                        disabled={loadingRoleAssociations}
                        startIcon={<Cancel />}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant='contained' color='primary' size='small'
                        onClick={() => handleDeleteAssociation(selectedRoleAssociation.id)}
                        sx={{ marginLeft: '1em' }}
                        disabled={loadingRoleAssociations}
                        startIcon={<Delete />}
                    >
                        Confirmar
                    </Button>
                </CardContent>
                {loadingRoleAssociations && (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                )}
            </Card>
        </Modal>
    )
}

export default DeleteAssociationModal
