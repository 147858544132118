import { actionTypes } from '../actions/users'
import { actionTypes as accessDataActionTypes } from '../actions/accessData'

const initialState = {
    loading: false,
    users: [],
}

export const getLoadingUsers = (state) => state.users.loading
export const getUsers = (state) => state.users.users

const users = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USERS_REQUEST:
        case actionTypes.TOGGLE_USER_STATUS_REQUEST:
        case actionTypes.CREATE_USER_REQUEST:
        case actionTypes.UPDATE_CREDENTIALS_REQUEST:
        case accessDataActionTypes.FETCH_ACCESS_DATA_REQUEST:
        case accessDataActionTypes.CREATE_ACCESS_DATA_REQUEST:
        case accessDataActionTypes.DELETE_ACCESS_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.FETCH_USERS_SUCCESS:
        case actionTypes.TOGGLE_USER_STATUS_SUCCESS:
        case actionTypes.CREATE_USER_SUCCESS:
        case actionTypes.UPDATE_CREDENTIALS_SUCCESS:
        case accessDataActionTypes.FETCH_ACCESS_DATA_SUCCESS:
        case accessDataActionTypes.CREATE_ACCESS_DATA_SUCCESS:
        case accessDataActionTypes.DELETE_ACCESS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.users,
            }
        case actionTypes.FETCH_USERS_FAILURE:
        case actionTypes.RESET_USERS:
            return {
                ...state,
                loading: false,
                users: [],
            }
        case actionTypes.TOGGLE_USER_STATUS_FAILURE:
        case actionTypes.CREATE_USER_FAILURE:
        case actionTypes.UPDATE_CREDENTIALS_FAILURE:
        case accessDataActionTypes.FETCH_ACCESS_DATA_FAILURE:
        case accessDataActionTypes.CREATE_ACCESS_DATA_FAILURE:
        case accessDataActionTypes.DELETE_ACCESS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}

export default users

export const getCountTotalUsers = (state) => {
    let count = 0
    if (state.users.users) {
        state.users.users.forEach(() => {
            count++
        })
    }
    return count
}

export const getCountDisabledUsers = (state) => {
    let count = 0
    if (state.users.users) {
        state.users.users.forEach((user) => {
            if (user.disabled) {
                count++
            }
        })
    }
    return count
}

export const getCountUsersWithoutAccess = (state) => {
    let count = getCountTotalUsers(state)
    if (state.users.users) {
        state.users.users.forEach((user) => {
            if (user.accessData && user.accessData.length > 0) {
                count--
            }
        })
    }
    return count
}

export const getCountCreatedWithinLastWeek = (state) => {
    let count = 0
    if (state.users.users && state.users.users.length > 0) {
        const todayObj = new Date();
        const todayDate = todayObj.getDate();
        const weekAgoDate = new Date(todayObj.setDate(todayDate - 7))
        state.users.users.forEach((user) => {
            if (new Date(user.created) > weekAgoDate) {
                count++
            }
        })

    }
    return count
}

export const getLastUpdatedUsername = (state) => {
    let name = ''
    if (state.users.users && state.users.users.length > 0) {
        const usersSortedByUpdated = state.users.users.sort((a, b) => (a.updated < b.updated) ? 1 : ((b.updated < a.updated) ? -1 : 0))
        name = usersSortedByUpdated[0].username
    }
    return name
}
