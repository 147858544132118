export const actionTypes = {
    SET_NOTIFICATION: 'SET_NOTIFICATION',
}

export const setNotification = (message, severity = 'error') => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_NOTIFICATION,
        message: message,
        severity: severity,
        show: true,
    })
    setTimeout(function () {
        dispatch({
            type: actionTypes.SET_NOTIFICATION,
            message: '',
            severity: 'success',
            show: false,
        })
    }, 3500)
}
