import { useState, Fragment, useEffect } from 'react'

import { AddCircleOutline, Delete } from '@mui/icons-material'
import { Box, Button, Card, CardContent, darken, Modal, Tooltip, Typography, useTheme } from '@mui/material'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from 'react-redux'

import CreateUserPermissionModal from './CreateUserPermissionModal'
import DeleteUserPermissionModal from './DeleteUserPermissionModal'
import { fetchPermissions } from '../../store/actions/permissions'
import { getCanCreateUserPermissions, getCanDeleteUserPermissions, getCanManageUserPermissions } from '../../store/reducers/abilities'
import { getLoadingPermissions, getPermissions } from '../../store/reducers/permissions'
import { bigModalStyle, tableModalStyle } from '../../styles/styles'
import CopyPermissionDataModal from './CopyPermissionDataModal'

const ConfigureUserPermissionsModal = (props) => {
    const { configureUserPermissionsModalOpen, handleCloseConfigureUserPermissionsModal, selectedUser, users } = props
    const [createUserPermissionModalOpen, setCreateUserPermissionModalOpen] = useState(false)
    const [copyPermissionDataModalOpen, setCopyAccessDataModalOpen] = useState(false)
    const [deleteUserPermissionModalOpen, setDeleteUserPermissionModalOpen] = useState(false)
    const [selectedUserPermission, setSelectedUserPermission] = useState(null)
    const dispatch = useDispatch()
    const theme = useTheme()
    const loadingPermissions = useSelector(getLoadingPermissions)
    const permissions = useSelector(getPermissions)
    const canCreateUserPermissions = useSelector(getCanCreateUserPermissions)
    const canManageUserPermissions = useSelector(getCanManageUserPermissions)
    const canDeleteUserPermissions = useSelector(getCanDeleteUserPermissions)

    const columns = [
        {
            accessorKey: 'id',
            header: 'Id',
            size: 20,
        },
        {
            accessorKey: 'tool',
            header: 'Herramienta',
        },
        {
            accessorKey: 'app',
            header: 'Aplicación',
        },
        {
            header: 'Acciones',
            Cell: (props) => {
                return (
                    <Fragment>
                        {canDeleteUserPermissions && (
                            <Tooltip title='Borrar permiso de usuario'>
                                <Button
                                    variant='contained'
                                    startIcon={<Delete />}
                                    onClick={() => handleOpenDeleteUserPermissionModal(props.row.original)}
                                    color='error'
                                    size='small'
                                >
                                    Borrar
                                </Button>
                            </Tooltip>
                        )}
                    </Fragment>
                )
            },
        },
    ]
    const handleOpenCreateUserPermissionModal = () => setCreateUserPermissionModalOpen(true)
    const handleOpenCopyPermissionDataModal = () => setCopyAccessDataModalOpen(true)


    const handleCloseCreateUserPermissionModal = () => setCreateUserPermissionModalOpen(false)
    const handleCloseCopyPermissionDataModal = () => setCopyAccessDataModalOpen(false)
    const handleOpenDeleteUserPermissionModal = (userPermission) => {
        setSelectedUserPermission(userPermission)
        setDeleteUserPermissionModalOpen(true)
    }
    const handleCloseDeleteUserPermissionModal = () => {
        setDeleteUserPermissionModalOpen(false)
        setSelectedUserPermission(null)
    }
    const handleCloseModal = () => handleCloseConfigureUserPermissionsModal()


    useEffect(() => {
        if (canManageUserPermissions) {
            dispatch(fetchPermissions(selectedUser.id))
        }
    }, [dispatch, canManageUserPermissions, selectedUser])

    return <Fragment>
        <Modal
            open={configureUserPermissionsModalOpen}
            onClose={handleCloseModal}
        >
            <Box sx={bigModalStyle}>
                <Card sx={tableModalStyle}>
                    <CardContent>
                        <Typography align='center' variant='h5'>
                            {'Configurar permisos de ' + selectedUser.username}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <MaterialReactTable
                            columns={columns}
                            data={permissions}
                            initialState={{ columnVisibility: { id: false }, density: 'compact' }}
                            state={{ isLoading: loadingPermissions }}
                            localization={MRT_Localization_ES}
                            renderTopToolbarCustomActions={() => canCreateUserPermissions ?
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title='Añadir nuevo permiso de usuario'>
                                        <Button
                                            variant='contained' color='success' size='small'
                                            startIcon={<AddCircleOutline />}
                                            onClick={() => handleOpenCreateUserPermissionModal(selectedUser)}
                                            disabled={loadingPermissions}
                                        >
                                            Nuevo permiso
                                        </Button>
                                    </Tooltip>

                                    <Box sx={{ ml: 1 }}>
                                        <Tooltip title='Copiar permisos de acceso de otro usuario'>
                                            <Button
                                                variant='contained' color='success' size='small'
                                                startIcon={<ContentCopyIcon />}
                                                onClick={() => handleOpenCopyPermissionDataModal(selectedUser)}
                                                disabled={loadingPermissions}
                                            >
                                                Copiar permisos
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Box>

                                : null
                            }
                            muiTableBodyProps={{
                                sx: () => ({
                                    '& tr:nth-of-type(odd)': {
                                        backgroundColor: darken(theme.palette.background.default, 0.05),
                                    },
                                }),
                            }}
                            muiTableProps={{
                                sx: {
                                    tableLayout: 'fixed',
                                },
                            }}
                        />
                    </CardContent>
                </Card>
            </Box>
        </Modal>
        {createUserPermissionModalOpen && (
            <CreateUserPermissionModal
                createUserPermissionModalOpen={createUserPermissionModalOpen}
                handleCloseCreateUserPermissionModal={handleCloseCreateUserPermissionModal}
                selectedUser={selectedUser}
            />
        )}
        {copyPermissionDataModalOpen && (
            <CopyPermissionDataModal
                copyPermissionDataModalOpen={copyPermissionDataModalOpen}
                handleCloseCopyPermissionDataModal={handleCloseCopyPermissionDataModal}
                selectedUser={selectedUser}
                users={users}
            />
        )}
        {deleteUserPermissionModalOpen && (
            <DeleteUserPermissionModal
                deleteUserPermissionModalOpen={deleteUserPermissionModalOpen}
                handleCloseDeleteUserPermissionModal={handleCloseDeleteUserPermissionModal}
                selectedUser={selectedUser}
                selectedUserPermission={selectedUserPermission}
            />
        )}
    </Fragment>
}

export default ConfigureUserPermissionsModal
