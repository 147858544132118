import { Fragment } from 'react'

import { Cancel, Save } from '@mui/icons-material'
import { Button, Card, CardContent, LinearProgress, Modal, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { object, string } from 'yup'

import { createApp } from '../../store/actions/apps'
import { getLoadingApps } from '../../store/reducers/apps'
import { modalStyle } from '../../styles/styles'

const CreateAppModal = (props) => {
    const { createAppModalOpen, handleCloseCreateAppModal } = props
    const dispatch = useDispatch()
    const loadingApps = useSelector(getLoadingApps)

    const createAppForm = useFormik({
        initialValues: {
            name: '',
            public_key: '',
        },
        validationSchema: object().shape({
            name: string()
                .required('Nombre de aplicación es obligatorio')
                .max(255, 'Nombre de aplicación tiene un máximo de 255 carácteres'),
            public_key: string()
                .required('Clave de aplicación es obligatoria')
                .max(255, 'Clave de aplicación tiene un máximo de 255 carácteres'),
        }),
        onSubmit: () => dispatch(createApp(createAppForm)),
        onReset: () => handleCloseCreateAppModal(),
    })

    const handleResetAndCloseModal = () => createAppForm.handleReset()

    return (
        <Modal
            open={createAppModalOpen}
            onClose={handleResetAndCloseModal}
        >
            <Card sx={modalStyle}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Nueva aplicación
                    </Typography>
                </CardContent>
                <CardContent>
                    <Fragment>
                        <TextField
                            variant='outlined' size='small'
                            id='name' label='Nombre'
                            error={createAppForm.touched.name && Boolean(createAppForm.errors.name)}
                            helperText={createAppForm.touched.name && createAppForm.errors.name}
                            value={createAppForm.values.name}
                            onChange={createAppForm.handleChange}
                            fullWidth
                        />
                        <TextField
                            variant='outlined' size='small'
                            id='public_key' label='Clave'
                            error={createAppForm.touched.public_key && Boolean(createAppForm.errors.public_key)}
                            helperText={createAppForm.touched.public_key && createAppForm.errors.public_key}
                            value={createAppForm.values.public_key}
                            onChange={createAppForm.handleChange}
                            fullWidth sx={{ marginTop: '1em' }}
                        />
                    </Fragment>
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='contained' color='error' size='small'
                        onClick={handleResetAndCloseModal}
                        disabled={loadingApps}
                        startIcon={<Cancel />}
                    >
                        Cerrar
                    </Button>
                    <Button
                        variant='contained' color='primary' size='small'
                        onClick={createAppForm.handleSubmit}
                        sx={{ marginLeft: '1em' }}
                        disabled={!createAppForm.dirty || loadingApps}
                        startIcon={<Save />}
                    >
                        Guardar
                    </Button>
                </CardContent>
                {loadingApps && (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                )}
            </Card>
        </Modal>
    )
}

export default CreateAppModal
