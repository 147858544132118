import { Fragment } from 'react'

import { Cancel, Save } from '@mui/icons-material'
import { Button, Card, CardContent, LinearProgress, Modal, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { object, string } from 'yup'

import { updateTool } from '../../store/actions/apps'
import { getLoadingApps } from '../../store/reducers/apps'
import { modalStyle } from '../../styles/styles'

const UpdateToolModal = (props) => {
    const { updateToolModalOpen, handleCloseUpdateToolModal, selectedTool } = props
    const dispatch = useDispatch()
    const loadingApps = useSelector(getLoadingApps)

    const updateToolForm = useFormik({
        initialValues: {
            id: selectedTool.id,
            name: selectedTool.name,
            tool_code: selectedTool.tool_code,
        },
        validationSchema: object().shape({
            id: string()
                .required('obligatorio'),
            name: string()
                .required('Nombre de herramienta es obligatorio')
                .max(255, 'Nombre de herramienta tiene un máximo de 255 carácteres'),
            tool_code: string()
                .required('Código de herramienta es obligatorio')
                .max(50, 'Código de herramienta tiene un máximo de 50 carácteres'),
        }),
        onSubmit: () => dispatch(updateTool(updateToolForm)),
        onReset: () => handleCloseUpdateToolModal(),
    })

    const handleResetAndCloseModal = () => updateToolForm.handleReset()

    return (
        <Fragment>
            <Modal
                open={updateToolModalOpen}
                onClose={handleResetAndCloseModal}
            >
                <Card sx={modalStyle}>
                    <CardContent>
                        <Fragment>
                            <TextField
                                variant='outlined' size='small'
                                id='name' label='Nombre'
                                error={updateToolForm.touched.name && Boolean(updateToolForm.errors.name)}
                                helperText={updateToolForm.touched.name && updateToolForm.errors.name}
                                value={updateToolForm.values.name}
                                onChange={updateToolForm.handleChange}
                                fullWidth
                            />

                            <TextField
                                variant='outlined' size='small'
                                id='tool_code' label='Código herramienta'
                                error={updateToolForm.touched.tool_code && Boolean(updateToolForm.errors.tool_code)}
                                helperText={updateToolForm.touched.tool_code && updateToolForm.errors.tool_code}
                                value={updateToolForm.values.tool_code}
                                onChange={updateToolForm.handleChange}
                                fullWidth sx={{ marginTop: '1em' }}
                            />
                        </Fragment>
                    </CardContent>
                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant='contained' color='error' size='small'
                            onClick={handleResetAndCloseModal}
                            disabled={loadingApps}
                            startIcon={<Cancel />}
                        >
                            Cerrar
                        </Button>
                        <Button
                            variant='contained' color='primary' size='small'
                            onClick={updateToolForm.handleSubmit}
                            sx={{ marginLeft: '1em' }}
                            disabled={!updateToolForm.dirty || loadingApps}
                            startIcon={<Save />}
                        >
                            Guardar
                        </Button>
                    </CardContent>
                    {loadingApps && (
                        <CardContent>
                            <LinearProgress />
                        </CardContent>
                    )}
                </Card>
            </Modal>
        </Fragment>
    )
}

export default UpdateToolModal
