import { useEffect } from 'react'

import { Cancel, Save } from '@mui/icons-material'
import { Autocomplete, Button, Card, CardContent, LinearProgress, Modal, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { object } from 'yup'

import { createPermission, fetchApps, fetchTools, resetApps, resetTools } from '../../store/actions/permissions'
import { getApps, getLoadingApps, getLoadingPermissions, getLoadingTools, getTools } from '../../store/reducers/permissions'
import { modalStyle } from '../../styles/styles'
import VirtualizedAutocomplete from '../VirtualizedAutoComplete'

const CreateUserPermissionModal = (props) => {
    const { createUserPermissionModalOpen, handleCloseCreateUserPermissionModal, selectedUser } = props
    const dispatch = useDispatch()
    const loadingApps = useSelector(getLoadingApps)
    const apps = useSelector(getApps)
    const loadingTools = useSelector(getLoadingTools)
    const tools = useSelector(getTools)
    const loadingPermissions = useSelector(getLoadingPermissions)
    const createUserPermissionForm = useFormik({
        initialValues: {
            app: null,
            tool: null,
        },
        validationSchema: object().shape({
            app: object().required('obligatorio'),
        }),
        onSubmit: () => {
            if (createUserPermissionForm.values.tool == null) {
                createUserPermissionForm.values.tool = -1
            }
            dispatch(createPermission(selectedUser, createUserPermissionForm))
        },
        // onSubmit: () => dispatch(createPermission(selectedUser, createUserPermissionForm)),
        onReset: () => handleCloseCreateUserPermissionModal(),
    })

    useEffect(() => {
        dispatch(fetchApps())
    }, [dispatch])

    const handleResetAndCloseModal = () => {
        createUserPermissionForm.handleReset()
        resetApps()
        resetTools()
    }

    const appFieldValue = createUserPermissionForm.values.app

    useEffect(() => {
        dispatch(resetTools())
        if (appFieldValue) {
            const appId = appFieldValue.id
            dispatch(fetchTools(appId))
        }
    }, [dispatch, appFieldValue])

    return (
        <Modal
            open={createUserPermissionModalOpen}
            onClose={handleResetAndCloseModal}
        >
            <Card sx={modalStyle}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Nuevo permiso de usuario
                    </Typography>
                </CardContent>
                <CardContent>
                    <Autocomplete
                        loading={loadingApps}
                        id='app'
                        options={apps}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='outlined'
                                label='Aplicación'
                                error={createUserPermissionForm.touched.app && Boolean(createUserPermissionForm.errors.app)}
                                helperText={createUserPermissionForm.touched.app && createUserPermissionForm.errors.app}
                            />
                        )}
                        onChange={(e, selectedApp) => createUserPermissionForm.setFieldValue('app', selectedApp || null)}
                        value={createUserPermissionForm.values.app}
                        size='small'
                    />
                    {createUserPermissionForm.values.app && (
                        <VirtualizedAutocomplete
                            options={tools}
                            loading={loadingTools}
                            form={createUserPermissionForm}
                            fieldName='tool'
                            sx={{ mt: '10px' }}
                            label='Herramienta'
                            helperText='Valor por defecto: Todas las herramientas'
                            onChange={(e, selectedTool) => createUserPermissionForm.setFieldValue('tool', selectedTool || null)}
                        />
                    )}
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='contained' color='error' size='small'
                        onClick={handleResetAndCloseModal}
                        disabled={loadingPermissions}
                        startIcon={<Cancel />}
                    >
                        Cerrar
                    </Button>
                    <Button
                        variant='contained' color='primary' size='small'
                        onClick={createUserPermissionForm.handleSubmit}
                        sx={{ marginLeft: '1em' }}
                        disabled={!createUserPermissionForm.dirty || loadingPermissions}
                        startIcon={<Save />}
                    >
                        Guardar
                    </Button>
                </CardContent>
                {loadingPermissions && (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                )}
            </Card>
        </Modal>
    )
}

export default CreateUserPermissionModal
