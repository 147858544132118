import { Fragment } from 'react'

import { Cancel, Save } from '@mui/icons-material'
import { Autocomplete, Button, Card, CardContent, LinearProgress, Modal, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { object } from 'yup'

import { createRoleAssociation } from '../../store/actions/roles'
import { getLoadingPoseidonRoles, getLoadingRoleAssociations, getLoadingRoles, getPoseidonRoles, getRoles } from '../../store/reducers/roles'
import { modalStyle } from '../../styles/styles'

const CreateAssociationModal = (props) => {
    const { createAssociationModalOpen, handleCloseCreateAssociationModal } = props
    const dispatch = useDispatch()
    const poseidonRoles = useSelector(getPoseidonRoles)
    const roles = useSelector(getRoles)
    const loadingRoles = useSelector(getLoadingRoles)
    const loadingPoseidonRoles = useSelector(getLoadingPoseidonRoles)
    const loadingRoleAssociations = useSelector(getLoadingRoleAssociations)

    const createRoleAssociationForm = useFormik({
        initialValues: {
            poseidonRole: null,
            role: null,
        },
        validationSchema: object().shape({
            poseidonRole: object().required('obligatorio'),
            role: object().required('obligatorio'),
        }),
        onSubmit: () => dispatch(createRoleAssociation(createRoleAssociationForm)),
        onReset: () => handleCloseCreateAssociationModal()
    })

    const handleResetAndCloseModal = () => createRoleAssociationForm.handleReset()

    return (
        <Modal
            open={createAssociationModalOpen}
            onClose={handleResetAndCloseModal}
        >
            <Card sx={modalStyle}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Nueva asociación de roles
                    </Typography>
                </CardContent>
                <CardContent>
                    <Fragment>
                        <Autocomplete
                            loading={loadingPoseidonRoles}
                            id='poseidonRole'
                            options={poseidonRoles}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Rol Poseidon'
                                    error={createRoleAssociationForm.touched.poseidonRole && Boolean(createRoleAssociationForm.errors.poseidonRole)}
                                    helperText={createRoleAssociationForm.touched.poseidonRole && createRoleAssociationForm.errors.poseidonRole}
                                />
                            )}
                            onChange={(e, selectedPoseidonRole) => createRoleAssociationForm.setFieldValue('poseidonRole', selectedPoseidonRole || null)}
                            value={createRoleAssociationForm.values.poseidonRole}
                            size='small'
                        />
                        <Autocomplete
                            loading={loadingRoles}
                            id='role'
                            options={roles}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Rol'
                                    error={createRoleAssociationForm.touched.role && Boolean(createRoleAssociationForm.errors.role)}
                                    helperText={createRoleAssociationForm.touched.role && createRoleAssociationForm.errors.role}
                                />
                            )}
                            onChange={(e, selectedRole) => createRoleAssociationForm.setFieldValue('role', selectedRole || null)}
                            value={createRoleAssociationForm.values.role}
                            size='small' sx={{ marginTop: '1em' }}
                        />
                    </Fragment>
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='contained' color='error' size='small'
                        onClick={handleCloseCreateAssociationModal}
                        disabled={loadingRoleAssociations}
                        startIcon={<Cancel />}
                    >
                        Cerrar
                    </Button>
                    <Button
                        variant='contained' color='primary' size='small'
                        onClick={createRoleAssociationForm.handleSubmit}
                        sx={{ marginLeft: '1em' }}
                        disabled={!createRoleAssociationForm.dirty || loadingRoleAssociations}
                        startIcon={<Save />}
                    >
                        Guardar
                    </Button>
                </CardContent>
                {loadingRoleAssociations && (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                )}
            </Card>
        </Modal >
    )
}

export default CreateAssociationModal
