import { actionTypes } from '../actions/permissions'

const initialState = {
    loading: false,
    loadingCopiedPermissions: false,
    loadingApps: false,
    loadinTools: false,
    permissions: [],
    copiedPermissions: [],
    apps: [],
    tools: [],
}

export const getLoadingPermissions = (state) => state.permissions.loading
export const getLoadingApps = (state) => state.permissions.loadingApps
export const getLoadingTools = (state) => state.permissions.loadinTools
export const getPermissions = (state) => state.permissions.permissions
export const getCopiedPermissions = (state) => state.permissions.copiedPermissions
export const getLoadingCopiedPermissions = (state) => state.permissions.loadingCopiedPermissions
export const getApps = (state) => state.permissions.apps
export const getTools = (state) => state.permissions.tools

const permissions = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PERMISSIONS_REQUEST:
        case actionTypes.CREATE_PERMISSION_REQUEST:
        case actionTypes.DELETE_PERMISSION_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.FETCH_PERMISSIONS_SUCCESS:
        case actionTypes.CREATE_PERMISSION_SUCCESS:
        case actionTypes.DELETE_PERMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: action.permissions
            }
        case actionTypes.FETCH_PERMISSIONS_FAILURE:
            return {
                ...state,
                loading: false,
                permissions: [],
            }
        case actionTypes.CREATE_PERMISSION_FAILURE:
        case actionTypes.DELETE_PERMISSION_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case actionTypes.FETCH_APPS_REQUEST:
            return {
                ...state,
                loadingApps: true,
            }
        case actionTypes.FETCH_APPS_SUCCESS:
            return {
                ...state,
                loadingApps: false,
                apps: action.apps,
            }
        case actionTypes.FETCH_APPS_FAILURE:
            return {
                ...state,
                loadingApps: false,
                apps: [],
            }
        case actionTypes.RESET_APPS:
            return {
                ...state,
                apps: [],
            }
        case actionTypes.FETCH_TOOLS_REQUEST:
            return {
                ...state,
                loadinTools: true,
            }
        case actionTypes.FETCH_TOOLS_SUCCESS:
            return {
                ...state,
                loadinTools: false,
                tools: action.tools,
            }
        case actionTypes.FETCH_TOOLS_FAILURE:
            return {
                ...state,
                loadinTools: false,
                tools: [],
            }
        case actionTypes.RESET_TOOLS:
            return {
                ...state,
                tools: [],
            }
        case actionTypes.FETCH_COPIED_PERMISSIONS_REQUEST:
            return {
                ...state,
                loadingCopiedPermissions: true,
            }

        case actionTypes.FETCH_COPIED_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loadingCopiedPermissions: false,
                copiedPermissions: action.permissions
            }
        case actionTypes.FETCH_COPIED_PERMISSIONS_FAILURE:
            return {
                ...state,
                loadingCopiedPermissions: false,
                permissions: [],
            }
        default:
            return state
    }
}

export default permissions
