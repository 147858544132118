import { setNotification } from './notifications'

export const actionTypes = {
    FETCH_ACCESS_DATA_REQUEST: 'FETCH_ACCESS_DATA_REQUEST',
    FETCH_ACCESS_DATA_SUCCESS: 'FETCH_ACCESS_DATA_SUCCESS',
    FETCH_ACCESS_DATA_FAILURE: 'FETCH_ACCESS_DATA_FAILURE',
    FETCH_COMPANIES_AND_CUSTOMERS_REQUEST: 'FETCH_COMPANIES_AND_CUSTOMERS_REQUEST',
    FETCH_COMPANIES_AND_CUSTOMERS_SUCCESS: 'FETCH_COMPANIES_AND_CUSTOMERS_SUCCESS',
    FETCH_COMPANIES_AND_CUSTOMERS_FAILURE: 'FETCH_COMPANIES_AND_CUSTOMERS_FAILURE',
    RESET_COMPANIES_AND_CUSTOMERS: 'RESET_COMPANIES_AND_CUSTOMERS',
    FETCH_CONTRACTS_REQUEST: 'FETCH_CONTRACTS_REQUEST',
    FETCH_CONTRACTS_SUCCESS: 'FETCH_CONTRACTS_SUCCESS',
    FETCH_CONTRACTS_FAILURE: 'FETCH_CONTRACTS_FAILURE',
    RESET_CONTRACTS: 'RESET_CONTRACTS',
    FETCH_SERVICES_REQUEST: 'FETCH_SERVICES_REQUEST',
    FETCH_SERVICES_SUCCESS: 'FETCH_SERVICES_SUCCESS',
    FETCH_SERVICES_FAILURE: 'FETCH_SERVICES_FAILURE',
    RESET_SERVICES: 'RESET_SERVICES',
    CREATE_ACCESS_DATA_REQUEST: 'CREATE_ACCESS_DATA_REQUEST',
    CREATE_ACCESS_DATA_SUCCESS: 'CREATE_ACCESS_DATA_SUCCESS',
    CREATE_ACCESS_DATA_FAILURE: 'CREATE_ACCESS_DATA_FAILURE',
    DELETE_ACCESS_DATA_REQUEST: 'DELETE_ACCESS_DATA_REQUEST',
    DELETE_ACCESS_DATA_SUCCESS: 'DELETE_ACCESS_DATA_SUCCESS',
    DELETE_ACCESS_DATA_FAILURE: 'DELETE_ACCESS_DATA_FAILURE',
}

const adminBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL

export const fetchCompaniesAndCustomers = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_COMPANIES_AND_CUSTOMERS_REQUEST })
    try {
        await fetch(adminBaseUrl + 'fetchCompaniesAndCustomers', {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const companies = data.payload.companies
                const customers = data.payload.customers
                dispatch({ type: actionTypes.FETCH_COMPANIES_AND_CUSTOMERS_SUCCESS, companies, customers })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_COMPANIES_AND_CUSTOMERS_FAILURE })
        dispatch(setNotification('Error recogiendo empresas', 'error'))
    }
}

export const resetCompaniesAndCustomers = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_COMPANIES_AND_CUSTOMERS })
}

export const fetchContracts = (companyId, customerId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_CONTRACTS_REQUEST, companyId, customerId })
    try {
        await fetch(adminBaseUrl + 'fetchContracts/' + companyId + '/' + customerId, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const contracts = data.payload.contracts
                dispatch({ type: actionTypes.FETCH_CONTRACTS_SUCCESS, contracts })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_CONTRACTS_FAILURE })
        dispatch(setNotification('Error recogiendo contratos', 'error'))
    }
}

export const resetContracts = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_CONTRACTS })
}

export const fetchServices = (contractId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_SERVICES_REQUEST, contractId })
    try {
        await fetch(adminBaseUrl + 'fetchServices/' + contractId, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const services = data.payload.services
                dispatch({ type: actionTypes.FETCH_SERVICES_SUCCESS, services })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_SERVICES_FAILURE })
        dispatch(setNotification('Error recogiendo servicios', 'error'))
    }
}

export const resetServices = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_SERVICES })
}


export const createAccessData = (selectedUser, createAccessDataForm) => async (dispatch, getState) => {
    const userId = selectedUser.id
    const companyId = createAccessDataForm.values.company.id
    const customerId = createAccessDataForm.values.customer.id
    const contractId = createAccessDataForm.values.contract && createAccessDataForm.values.contract.id !== 0 ? createAccessDataForm.values.contract.id : null
    const serviceId = createAccessDataForm.values.service && createAccessDataForm.values.service.id !== 0 ? createAccessDataForm.values.service.id : null
    const data = { companyId, customerId, contractId, serviceId }
    dispatch({ type: actionTypes.CREATE_ACCESS_DATA_REQUEST, userId, data })
    try {
        await fetch(adminBaseUrl + 'createAccessData/' + userId, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.success) {
                    if (data.errors) {
                        dispatch({ type: actionTypes.CREATE_ACCESS_DATA_FAILURE })
                        dispatch(setNotification('Error configurando dato de acceso', 'error'))
                        createAccessDataForm.setErrors(data.errors)
                    } else {
                        dispatch({ type: actionTypes.CREATE_ACCESS_DATA_FAILURE })
                        dispatch(setNotification('El usuario ya tiene configurado este dato de acceso', 'error'))
                    }
                } else if (data.payload && data.payload.users) {
                    const users = data.payload.users
                    dispatch({ type: actionTypes.CREATE_ACCESS_DATA_SUCCESS, users })
                    dispatch(setNotification('Dato de acceso ha sido configurado', 'success'))
                    createAccessDataForm.handleReset()
                }
            })
    } catch (error) {
        dispatch({ type: actionTypes.CREATE_ACCESS_DATA_FAILURE })
        dispatch(setNotification('Error configurando dato de acceso', 'error'))
    }
}
export const copyAccessData = (selectedUser, copyAccessDataForm) => async (dispatch, getState) => {
    const userId = selectedUser.id
    const copyUserId = copyAccessDataForm.values.user.id

    const data = { copyUserId }
    dispatch({ type: actionTypes.CREATE_ACCESS_DATA_REQUEST, userId, data })
    if (copyUserId === userId) {
        dispatch({ type: actionTypes.CREATE_ACCESS_DATA_FAILURE })
        dispatch(setNotification('Has seleccionado el mismo usuario', 'error'))
    } else {
        if (copyAccessDataForm.values.user.accessData.length === 0) {
            dispatch({ type: actionTypes.CREATE_ACCESS_DATA_FAILURE })
            dispatch(setNotification('Datos de acceso seleccionados vacios', 'error'))
        } else {
            try {
                await fetch(adminBaseUrl + 'copyAccessData/' + userId, {
                    credentials: 'include',
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.success) {
                            if (data.errors) {
                                dispatch({ type: actionTypes.CREATE_ACCESS_DATA_FAILURE })
                                dispatch(setNotification('Error copiando dato de acceso', 'error'))
                                copyAccessDataForm.setErrors(data.errors)
                            } else {
                                dispatch({ type: actionTypes.CREATE_ACCESS_DATA_FAILURE })
                                dispatch(setNotification('El usuario ya tiene esos datos de acceso', 'error'))
                            }
                        } else if (data.payload && data.payload.users) {
                            const users = data.payload.users
                            dispatch({ type: actionTypes.CREATE_ACCESS_DATA_SUCCESS, users })
                            dispatch(setNotification('Datos de acceso ha sido copiados', 'success'))
                            copyAccessDataForm.handleReset()
                        }
                    })
            } catch (error) {
                dispatch({ type: actionTypes.CREATE_ACCESS_DATA_FAILURE })
                dispatch(setNotification('Error copiando datos de acceso', 'error'))
            }
        }
    }
}

export const deleteAccessData = (accessDataId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.DELETE_ACCESS_DATA_REQUEST, accessDataId })
    try {
        await fetch(adminBaseUrl + 'deleteAccessData/' + accessDataId, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const users = data.payload.users
                dispatch({ type: actionTypes.DELETE_ACCESS_DATA_SUCCESS, users })
                dispatch(setNotification('Dato de acceso ha sido borrado', 'success'))
            })
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_ACCESS_DATA_FAILURE })
        dispatch(setNotification('Error borrando dato de acceso', 'error'))
    }
}
