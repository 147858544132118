import jwtDecode from 'jwt-decode'
import { actionTypes } from '../actions/abilities'

const initialState = {
    loading: false,
    abilities: '',
}

export const getLoadingAbilities = (state) => state.abilities.loading

const abilities = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ABILITIES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.FETCH_ABILITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                abilities: action.abilities,
            }
        case actionTypes.FETCH_ABILITIES_FAILURE:
            return {
                ...state,
                loading: false,
                abilities: '',
            }
        default:
            return state
    }
}

export default abilities

export const getCanManageUsers = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('manage-users')
    }
}
export const getCanManageApps = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('manage-apps')
    }
}
export const getCanManageRoleAssociations = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('manage-role-associations')
    }
}
export const getCanManageAcessData = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('manage-access-data')
    }
}
export const getCanManageUserPermissions = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('manage-user-permissions')
    }
}
export const getCanCreateRoleAssociations = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('create-role-associations')
    }
}
export const getCanDeleteRoleAssociatons = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('delete-role-associations')
    }
}
export const getCanCreateApps = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('create-apps')
    }
}
export const getCanUpdateApps = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('update-apps')
    }
}
export const getCanCreateTools = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('create-tools')
    }
}
export const getCanUpdateTools = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('update-tools')
    }
}
export const getCanDeleteTools = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('delete-tools')
    }
}
export const getCanCreateUsers = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('create-users')
    }
}
export const getCanUpdateUsers = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('update-users')
    }
}
export const getCanDeleteUsers = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('delete-users')
    }
}
export const getCanCreateAccessData = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('create-access-data')
    }
}
export const getCanDeleteAccessData = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('delete-access-data')
    }
}
export const getCanCreateUserPermissions = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('create-user-permissions')
    }
}
export const getCanDeleteUserPermissions = (state) => {
    if (state.abilities.abilities) {
        return jwtDecode(state.abilities.abilities).includes('delete-user-permissions')
    }
}
