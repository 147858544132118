import { setNotification } from './notifications'

export const actionTypes = {
    FETCH_PERMISSIONS_REQUEST: 'FETCH_PERMISSIONS_REQUEST',
    FETCH_PERMISSIONS_SUCCESS: 'FETCH_PERMISSIONS_SUCCESS',
    FETCH_PERMISSIONS_FAILURE: 'FETCH_PERMISSIONS_FAILURE',
    FETCH_APPS_REQUEST: 'FETCH_APPS_REQUEST',
    FETCH_APPS_SUCCESS: 'FETCH_APPS_SUCCESS',
    FETCH_APPS_FAILURE: 'FETCH_APPS_FAILURE',
    RESET_APPS: 'RESET_APPS',
    FETCH_TOOLS_REQUEST: 'FETCH_TOOLS_REQUEST',
    FETCH_TOOLS_SUCCESS: 'FETCH_TOOLS_SUCCESS',
    FETCH_TOOLS_FAILURE: 'FETCH_TOOLS_FAILURE',
    RESET_TOOLS: 'RESET_TOOLS',
    CREATE_PERMISSION_REQUEST: 'CREATE_PERMISSION_REQUEST',
    CREATE_PERMISSION_SUCCESS: 'CREATE_PERMISSION_SUCCESS',
    CREATE_PERMISSION_FAILURE: 'CREATE_PERMISSION_FAILURE',
    DELETE_PERMISSION_REQUEST: 'DELETE_PERMISSION_REQUEST',
    DELETE_PERMISSION_SUCCESS: 'DELETE_PERMISSION_SUCCESS',
    DELETE_PERMISSION_FAILURE: 'DELETE_PERMISSION_FAILURE',
    FETCH_COPIED_PERMISSIONS_REQUEST: 'FETCH_COPIED_PERMISSIONS_REQUEST',
    FETCH_COPIED_PERMISSIONS_SUCCESS: 'FETCH_COPIED_PERMISSIONS_SUCCESS',
    FETCH_COPIED_PERMISSIONS_FAILURE: 'FETCH_COPIED_PERMISSIONS_FAILURE',
}

const adminBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL

export const fetchPermissions = (userId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_PERMISSIONS_REQUEST, userId })
    try {
        await fetch(adminBaseUrl + 'fetchPermissions/' + userId, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const permissions = data.payload.permissions
                dispatch({ type: actionTypes.FETCH_PERMISSIONS_SUCCESS, permissions })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_PERMISSIONS_FAILURE })
        dispatch(setNotification('Error recogiendo permisos de usuario', 'error'))
    }
}

export const fetchCopiedPermissions = (userId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_COPIED_PERMISSIONS_REQUEST, userId })
    try {
        await fetch(adminBaseUrl + 'fetchPermissions/' + userId, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const permissions = data.payload.permissions
                dispatch({ type: actionTypes.FETCH_COPIED_PERMISSIONS_SUCCESS, permissions })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_COPIED_PERMISSIONS_FAILURE })
        dispatch(setNotification('Error recogiendo permisos de usuario', 'error'))
    }
}

export const fetchApps = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_APPS_REQUEST })
    try {
        await fetch(adminBaseUrl + 'fetchAppsForUserPermissions', {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const apps = data.payload.apps
                dispatch({ type: actionTypes.FETCH_APPS_SUCCESS, apps })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_APPS_FAILURE })
        dispatch(setNotification('Error recogiendo aplicaciones', 'error'))
    }
}

export const resetApps = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_APPS })
}

export const fetchTools = (appId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_TOOLS_REQUEST, appId })
    try {
        await fetch(adminBaseUrl + 'fetchTools/' + appId, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const tools = data.payload.tools
                dispatch({ type: actionTypes.FETCH_TOOLS_SUCCESS, tools })
            })
    } catch (error) {
        dispatch({ type: actionTypes.FETCH_TOOLS_FAILURE })
        dispatch(setNotification('Error recogiendo herramientas', 'error'))
    }
}

export const resetTools = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_TOOLS })
}

export const createPermission = (selectedUser, createUserPermissionForm) => async (dispatch, getState) => {
    const userId = selectedUser.id
    const appId = createUserPermissionForm.values.app.id
    const toolId = createUserPermissionForm.values.tool.id === -1 ? null : createUserPermissionForm.values.tool.id
    const data = { appId, toolId }

    dispatch({ type: actionTypes.CREATE_PERMISSION_REQUEST, userId, data })
    try {
        await fetch(adminBaseUrl + 'createPermission/' + userId, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data.success) {
                    if (data.errors) {
                        dispatch({ type: actionTypes.CREATE_PERMISSION_FAILURE })
                        dispatch(setNotification('Error creando permiso de usuario', 'error'))
                        createUserPermissionForm.setErrors(data.errors)
                    } else {
                        dispatch({ type: actionTypes.CREATE_PERMISSION_FAILURE })
                        dispatch(setNotification('El usuario ya tiene configurado este permiso', 'error'))
                    }
                } else if (data.success && data.payload && data.payload.permissions) {
                    const permissions = data.payload.permissions
                    dispatch({ type: actionTypes.CREATE_PERMISSION_SUCCESS, permissions })
                    dispatch(setNotification('Permiso de usuario ha sido creado', 'success'))
                    createUserPermissionForm.handleReset()
                }
            })
    } catch (error) {
        dispatch({ type: actionTypes.CREATE_PERMISSION_FAILURE })
        dispatch(setNotification('Error creando permiso de usuario', 'error'))
    }
}

export const copyPermission = (selectedUser, copyPermissionDataForm, permissions) => async (dispatch, getState) => {
    const userId = selectedUser.id
    const copyUserId = copyPermissionDataForm.values.user.id ?? null
    const data = { copyUserId, permissions }

    dispatch({ type: actionTypes.CREATE_PERMISSION_REQUEST, userId, data })
    if (copyUserId === userId) {
        dispatch({ type: actionTypes.CREATE_PERMISSION_FAILURE })
        dispatch(setNotification('Has seleccionado el mismo usuario', 'error'))
    } else {
        if (permissions.length === 0) {
            dispatch({ type: actionTypes.CREATE_PERMISSION_FAILURE })
            dispatch(setNotification('Permisos del usuario seleccionado vacios', 'error'))
        } else {
            try {
                await fetch(adminBaseUrl + 'copyPermission/' + userId, {
                    credentials: 'include',
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.success) {
                            if (data.errors) {
                                dispatch({ type: actionTypes.CREATE_PERMISSION_FAILURE })
                                dispatch(setNotification('Error creando permiso de usuario', 'error'))
                                copyPermissionDataForm.setErrors(data.errors)
                            } else {
                                dispatch({ type: actionTypes.CREATE_PERMISSION_FAILURE })
                                dispatch(setNotification('El usuario ya tiene configurado este permiso', 'error'))
                            }
                        } else if (data.success && data.payload && data.payload.permissions) {
                            const permissions = data.payload.permissions
                            dispatch({ type: actionTypes.CREATE_PERMISSION_SUCCESS, permissions })
                            dispatch(setNotification('Permiso de usuario ha sido creado', 'success'))
                            copyPermissionDataForm.handleReset()
                        }
                    })
            } catch (error) {
                dispatch({ type: actionTypes.CREATE_PERMISSION_FAILURE })
                dispatch(setNotification('Error creando permiso de usuario', 'error'))
            }
        }

    }
}

export const deletePermission = (userId, permissionId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.DELETE_PERMISSION_REQUEST, userId, permissionId })
    try {
        await fetch(adminBaseUrl + 'deletePermission/' + userId + '/' + permissionId, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const permissions = data.payload.permissions
                dispatch({ type: actionTypes.DELETE_PERMISSION_SUCCESS, permissions })
                dispatch(setNotification('Permiso de usuario ha sido borrado', 'success'))
            })
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_PERMISSION_FAILURE })
        dispatch(setNotification('Error borrando permiso de usuario', 'error'))
    }
}
