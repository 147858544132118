import { Cancel, Save } from '@mui/icons-material'
import { Button, Card, CardContent, LinearProgress, Modal, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { object, string } from 'yup'

import { createUser } from '../../store/actions/users'
import { getLoadingUsers } from '../../store/reducers/users'
import { modalStyle } from '../../styles/styles'

const CreateUserModal = (props) => {
    const { createUserModalOpen, handleCloseCreateUserModal } = props
    const dispatch = useDispatch()
    const loadingUsers = useSelector(getLoadingUsers)

    const createUserForm = useFormik({
        initialValues: {
            username: '',
        },
        validationSchema: object().shape({
            username: string()
                .required('obligatorio')
                .max(255)
                .email('debe ser una dirección de correo electrónico'),
        }),
        onSubmit: () => dispatch(createUser(createUserForm)),
        onReset: () => handleCloseCreateUserModal()
    })

    const handleResetAndCloseModal = () => createUserForm.handleReset()

    return (
        <Modal
            open={createUserModalOpen}
            onClose={handleResetAndCloseModal}
        >
            <Card sx={modalStyle}>
                <CardContent>
                    <Typography align='center' variant='h5'>
                        Nuevo usuario
                    </Typography>
                </CardContent>
                <CardContent>
                    <TextField
                        size='small' variant='outlined'
                        id='username' label='Nombre usuario'
                        error={createUserForm.touched.username && Boolean(createUserForm.errors.username)}
                        helperText={createUserForm.touched.username && createUserForm.errors.username}
                        value={createUserForm.values.username}
                        onChange={createUserForm.handleChange}
                        fullWidth sx={{ marginTop: '1em' }}
                    />
                </CardContent>
                <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant='contained' color='error' size='small'
                        onClick={handleResetAndCloseModal}
                        disabled={loadingUsers}
                        startIcon={<Cancel />}
                    >
                        Cerrar
                    </Button>
                    <Button
                        variant='contained' color='primary' size='small'
                        onClick={createUserForm.handleSubmit}
                        sx={{ marginLeft: '1em' }}
                        disabled={!createUserForm.dirty || loadingUsers}
                        startIcon={<Save />}
                    >
                        Guardar
                    </Button>
                </CardContent>
                {loadingUsers && (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                )}
            </Card>

        </Modal>
    )
}

export default CreateUserModal
